import './findus.css';
import React, { useEffect } from 'react';
import {   Card, CardActions, CardContent, makeStyles,  Typography } from '@material-ui/core';
import { useState } from 'react';
import RightbarNav from '../../RightBarNav';
import Leftbar from '../../Leftbar';
import GoogleMapComponent from '../../GoogleMapComponent';
import { Email, LocationOn } from '@material-ui/icons';




const FindUs = ({user, toggleLeftBar, setToggleLeftBar}) => {


    useEffect(() => {
        setToggleLeftBar(false);
    }, [])

    return (
        <div className="findUs">
            <div className="menusidebar">
                <Leftbar />
            </div> 
            <div className="mobilemenusidebar" style={ toggleLeftBar ? {width: "200px"} : undefined}>
                <Leftbar toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
            </div> 
            <div className="findUsFeed">
                <RightbarNav pageTitle = "Find Us" toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
                <div className='findUsInfo'>
                    <div className="findUsInfoItem">
                        <Card style={{minWidth: "255px"}}>
                            <CardContent>
                                <Email fontSize="large" className="findUsIcons"/>
                                <Typography variant="h6">
                                    info@techbaseng.com
                                </Typography>
                            </CardContent>
                        </Card>                       
                    </div>

                    <div className="findUsInfoItem">
                        <Card style={{maxWidth: "550px"}}>
                            <CardContent>
                            <LocationOn fontSize="large" className="findUsIcons"/>
                            <Typography variant="h6">
                                2, Olorunisola Street, Allen Ikeja, Lagos
                            </Typography>
                            </CardContent>
                        </Card>                       
                    </div>
                </div>
                <div className="findUsMap">
                    <GoogleMapComponent/>
                </div>
            </div>
        </div>
    )
}

export default FindUs


