import { Typography } from '@material-ui/core';
import { AccountCircle, AddShoppingCart as AddShoppingCartIcon, NotificationsActive } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { Link } from 'react-router-dom';
import { theme } from '../theme';

const useStyles = makeStyles({
    container: {
      marginBottom: "10px",
      display: "flex",
      paddingRight:"10px",
      paddingTop: "20px",
      paddingLeft: "10px",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px"
    }
    },

    pagetitle: {
      [theme.breakpoints.down("sm")]: {
          display: "none"
      }
    },

    topLeft: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
          marginBottom: theme.spacing(3),
          cursor: "pointer"
      }
    },
  
    topRight: {
      display: "flex",
    },

    avatar: {
      width: 25,
      height: 25,
      borderRadius: "50%",
      cursor: "pointer"
    },

    icons: {
      marginRight: 12,
      color: "#1C1045"
    },

    mobilemenuicon: {
      marginRight: 12,
      color: "#1C1045",
      '@media (min-width: 700px)': {
        display: 'none',
      },  
    },

    icon: {
      fontSize: 30
    }
    
    
  });



const RightbarNav = ({ pageTitle, toggleLeftBar, handleToggleLeftBar }) => {
    const classes = useStyles();

    const openLeftBar = () => {
      handleToggleLeftBar(!toggleLeftBar);
    }

    return (
        //  <div className={ classes.container }>
        //     <div className={classes.topLeft}>
        //         {/* <Typography className={ classes.pagetitle } variant="h5" style={{ paddingTop: 0 }}> { pageTitle } </Typography> */}
        //         <div className= {classes.mobilemenuicon} onClick={() => openLeftBar()}>
        //             <MenuIcon className={classes.icon}/>
        //         </div>
        //     </div>  
            
        //     <div className= {classes.topRight}>
        //       {/* <div className= {classes.icons}>
        //         <Search/>
        //       </div> */}
              
        //       {/* <Link to="/request">
        //         <div className= {classes.icons}>
        //           <AddShoppingCartIcon />
        //         </div>
        //       </Link>

        //       <Link to="/notifications">
        //         <div className= {classes.icons}>               
        //           <NotificationsActive/>
        //         </div>
        //       </Link>
        //       <Link to="/profile">
        //         <div className= {classes.icons}> */}
        //           {/* <img src="https://picsum.photos/200/100" alt="" srcset="" className={ classes.avatar }/> */}
        //           {/* <AccountCircle/>
        //         </div>
        //       </Link> */}
              
        //     </div>
        //  </div>  
        
        <div className=''>

        </div>
        
    )
}

export default RightbarNav
