import { ArrowRight } from "iconsax-react";
import Button from "./Button";
import FormInput from "./FormInput";

export default function RepairOption({goBack, onSubmit, setIsHomePickup, isHomePickup}) {
    return (
        <div className="flex flex-col">
            <p className="text-primary-300 text-[40px] font-semibold mb-8">Choose a <span className="text-[#98A2B3]">repair</span> option.</p>
            <div className="flex md:flex-row flex-col mb-10 gap-6">
                <div className="border border-[#F2F4F7] p-4 flex items-start rounded-lg max-w-[342px] cursor-pointer" onClick={() => setIsHomePickup(true)}>
                    <div className="flex flex-col">
                        <div className="flex justify-start mb-4">
                            <div className="border-[#F9FAFB] bg-[#F2F4F7] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.5 18.3337V10.0003H12.5V18.3337M2.5 7.50033L10 1.66699L17.5 7.50033V16.667C17.5 17.109 17.3244 17.5329 17.0118 17.8455C16.6993 18.1581 16.2754 18.3337 15.8333 18.3337H4.16667C3.72464 18.3337 3.30072 18.1581 2.98816 17.8455C2.67559 17.5329 2.5 17.109 2.5 16.667V7.50033Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>

                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-primary-300 font-medium">Home pickup</p>
                            <p className="text-sm text-primary-400">We'll come to you pickup your device for a fix and deliver to your doorstep after repair.</p>
                        </div>
                    </div>
                    <input type="radio" name="" id="" checked={isHomePickup}/>
                </div>
                <div className="border border-[#F2F4F7] p-4 flex items-start rounded-lg max-w-[342px] cursor-pointer" onClick={() => setIsHomePickup(false)}>
                    <div className="flex flex-col">
                        <div className="flex justify-start mb-4">
                            <div className="border-[#F9FAFB] bg-[#F2F4F7] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.55518 7.58191L10.8868 8.46274C11.2152 8.52858 11.471 8.78441 11.5377 9.11274L12.4177 13.4444L15.3493 4.65024L6.55518 7.58191ZM12.2227 17.4994C12.2052 17.4994 12.1885 17.4986 12.1718 17.4977C11.7952 17.4752 11.481 17.2019 11.4052 16.8327L10.0143 9.98524L3.16768 8.59358C2.79768 8.51858 2.52435 8.20441 2.50185 7.82774C2.47851 7.45108 2.71185 7.10608 3.06935 6.98691L16.4027 2.54274C16.7027 2.44108 17.0327 2.52024 17.256 2.74358C17.4793 2.96691 17.5568 3.29691 17.4577 3.59691L13.0127 16.9302C12.8985 17.2719 12.5793 17.4994 12.2227 17.4994Z" fill="#121212" />
                                </svg>
                            </div>
                        </div>

                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-primary-300 font-medium">Drop-off at Repair Shop</p>
                            <p className="text-sm text-primary-400">Prefer swinging by? Drop off your device at our repair drop-off location.</p>
                        </div>
                    </div>
                    <input type="radio" name="" id="" checked={!isHomePickup} />
                </div>
            </div>

            <div className="flex w-full">
                <div className="flex gap-3">
                    <Button isSecondary={true} onButtonClick={goBack}>
                        Go back
                    </Button>
                    <Button onButtonClick={onSubmit}>
                        <div className="flex text-white gap-2 items-center">
                            <p className="text-base font-semibold">Continue</p>
                            <ArrowRight size="20" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}