import { Modal } from "antd";
import { BeatLoader } from "react-spinners";

export default function LoadingModal({ title, open, onClose }) {
    return (
        <Modal open={open}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            onCancel={onclose}>
            <div className="flex font-inter w-full flex-col justify-center">
                <div className="flex justify-center p-0 mb-5">
                    <div className="border-[#F9FAFB] bg-[#F2F4F7] rounded-[39px] border-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5083 13.9905L18.2017 22.2722L15.48 18.7955C14.9133 18.0688 13.8633 17.9422 13.14 18.5105C12.415 19.0772 12.2867 20.1255 12.855 20.8488L16.9067 26.0272C17.2233 26.4305 17.7067 26.6655 18.22 26.6655H18.2317C18.7467 26.6638 19.2333 26.4205 19.545 26.0088L27.16 16.0088C27.7183 15.2755 27.5767 14.2322 26.8433 13.6738C26.1083 13.1155 25.065 13.2588 24.5083 13.9905ZM20.0007 33.333C12.649 33.333 6.66732 27.3513 6.66732 19.9997C6.66732 12.648 12.649 6.66634 20.0007 6.66634C27.3523 6.66634 33.334 12.648 33.334 19.9997C33.334 27.3513 27.3523 33.333 20.0007 33.333ZM20.0007 3.33301C10.7957 3.33301 3.33398 10.7963 3.33398 19.9997C3.33398 29.203 10.7957 36.6663 20.0007 36.6663C29.2057 36.6663 36.6673 29.203 36.6673 19.9997C36.6673 10.7963 29.2057 3.33301 20.0007 3.33301Z" fill="#98A2B3" />
                        </svg>
                    </div>
                </div>
                <div className="flex justify-center mb-4">
                    <p className="text-lg text-primary-400 font-semibold">{title}</p>
                </div>
                <div className="flex justify-center">
                    <BeatLoader color='#F04301' size={20} />
                </div>
            </div>

        </Modal>
    )
}