export function formatTimeFromDate(date) {
    let d = new Date(date);
    let hours = d.getHours() ;
    // let hours = (d.getHours()) -1 ;
    let minutes = d.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}