import React, { createRef, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom'
import { useState } from 'react';
import RightbarNav from '../../RightBarNav';
import Leftbar from '../../Leftbar';
import './styles.css';
import { BeatLoader, ClipLoader } from "react-spinners";
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import axiosInstance from '../../../helpers/axios';
import { formatDate } from '../../../helpers/date';
import { formatTimeFromDate } from '../../../helpers/timeFromDate';
import { FaArrowRight } from "react-icons/fa6";
import { ArrowRight2, Flash } from 'iconsax-react';
import Slider from 'react-slick';
import { Image, Modal, message } from 'antd';
import FormInput from '../../FormInput';
import axios from 'axios';
import { format } from "date-fns";
import { Progress } from 'antd';
import { Table } from 'antd';
import ConfirmationModal from '../../ConfirmationModal';
import Layout from '../../Layout';
import RepairTable from '../../RepairTable';
import LoadingModal from '../../LoadingModal';
import { useHistory } from 'react-router';


const ActiveOrders = ({ user, toggleLeftBar, setToggleLeftBar }) => {
    const orderString = localStorage.getItem('order')
    const history = useHistory()
    const [order, setOrder] = useState();

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    const inputRef = useRef(null)
    const [userOrders, setUserOrders] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [filteredUserOrders, setFilteredUserOrders] = useState([]);
    const [tableLoadingProgress, setTableLoadingProgress] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const [openLoadingModal, setOpenLoadingModal] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [showMobileNav, setShowMobileNav] = useState(false)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searched, setSearched] = useState("");
    // const [imageSrc, setImageSrc] = useState('');

    const childRef = createRef();


    const quickFixes = [
        {
            title: 'Battery',
            image: 'battery.png',
            path: ''
        },
        {
            title: 'Screen',
            image: 'phone.png',
            path: ''
        },
        {
            title: 'Charging Port',
            image: 'usb-port.png',
            path: ''
        },
        {
            title: 'Water Damage',
            image: 'water-damage.png',
            path: ''
        },
    ]

    function getStatusChip(record) {
        switch (record.orderStatusId) {
            case 1:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#EEF3FF] rounded-2xl text-[#114FEE] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#ECFDF3] rounded-2xl text-[#027A48] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
        }
    }

    function getStatusProgress(orderStatusId) {
        switch (Number(orderStatusId)) {
            case 1:
                return (
                    <Progress percent={20} strokeColor={'#F04301'} />
                );
            case 2:
                return (
                    <Progress percent={60} strokeColor={'#F04301'} />
                );
            case 5:
                return (
                    <Progress percent={100} strokeColor={'#32D583'} />
                );

        }
    }

    const columns = [
        {
            title: 'Repair ID',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (date) => format(new Date(date), "MMM dd, yyyy")
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <div className='flex flex-col'>
                    {getStatusChip(record)}
                    {getStatusProgress(record.orderStatusId)}
                </div>
            ),
        },
        {
            title: 'Action',
            render: (_, record) => (
                <Link to="#">
                    <p className='text-[#F04301] text-sm font-semibold'>View Details</p>
                </Link>
            ),
        },
    ]



    function getGenerateErrorResponse(error) {
        if (error.response) {
            message.error(error.response.data.info)
        } else {
            message.error('Oops! Something went wrong. Please try again later')
        }
        setIsButtonLoading(false)
    }


    function handleViewRepairDetails() {
        history.push(`/request/details/${order?.id}`)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function showModal() {
        setOpen(true)
    }
    function showConfirmationModal() {
        setOpenConfirmationModal(true)
    }
    function hideModal() {
        setOpen(false)
    }
    function hideConfirmationModal() {
        setOpenConfirmationModal(false)
    }

    // FETCH CUSTOMER ORDERS
    useEffect(() => {
        const fetchUserOrders = async () => {
            try {
                const res = await axiosInstance.get(`/Order/Customer/${user.id}`);
                if (res.data.success === true) {
                    setTableData(res.data.data)
                }
            } catch (error) {

            }
            setTableLoadingProgress(false);
        }
        fetchUserOrders();

    }, []);

    //Sumbit order
    useEffect(() => {
        const submitOrder = async () => {
            if (!orderString) { return; }
            setOpenLoadingModal(true)
            const ordObj = JSON.parse(orderString)
            const formData = new FormData();
            formData.append('Type', ordObj.deviceType)
            formData.append('Complaint', ordObj.complaint)
            formData.append('UserId', user.id)

            try {
                const result = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/Order`, formData)
                setOrder(result.data.data)
                showConfirmationModal()
                localStorage.removeItem('order')

            } catch (error) {
                getGenerateErrorResponse(error)
            }
            setOpenLoadingModal(false)
        }
        submitOrder();

    }, []);

    useEffect(() => {
        setToggleLeftBar(false);
    }, [])

    function showModal() {
        childRef.current.showModal()
    }

    const requestSearch = (searchedVal) => {
        if (!searchedVal) {
            setUserOrders(userOrders);
        }

        let newUserOrders = [...userOrders];
        const filteredRows = newUserOrders.filter((row) => {
            return row.displayName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilteredUserOrders(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };


    const rows = userOrders.map((order) => {
        let orderId = order.id;
        let orderRef = order.guid;
        let orderDisplayName = order.displayName;
        let orderStatus = order.status;
        let orderDate = formatDate(order.orderDate) + ' ' + formatTimeFromDate(order.orderDate);
        let hasDiagnosis = order.hasDiagnosis;
        let isPaid = order.isPaid;

        return { orderId, orderRef, orderDisplayName, orderStatus, orderDate, hasDiagnosis, isPaid };
    })

    return (
        <Layout pageTitle={'Dashboard'} showMobileNav={showMobileNav} user={user}>
            <>
                {/* Confirmation modal */}
                <ConfirmationModal open={openConfirmationModal}
                    secondaryButtonText='View order details' primaryButtonText='Done'
                    mainTitle='Your order has been submitted successfully'
                    subTitle='You will receive an email confirming your order'
                    hideModal={hideConfirmationModal}
                    primaryButtonClick={hideConfirmationModal}
                    secondaryButtonClick={handleViewRepairDetails}
                />
                <LoadingModal title='Please hold on while we process your order' open={openLoadingModal} />
                <div className="flex justify-between items-start">
                    <div className="flex flex-col gap-2 mb-8">
                        <p className="text-2xl md:text-3xl text-primary-300 font-semibold">Hello, {user.firstName} 👋</p>
                        <p className="text-base text-primary-100 w-[250px] md:w-[344px]">Make sure you are using a screen protector on your mobile phones.</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowMobileNav(!showMobileNav)} className='md:hidden cursor-point' width="38" height="38" viewBox="0 0 40 40" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58049 10H33.4188C34.2888 10 35.0005 10.7117 35.0005 11.5817V11.7517C35.0005 12.6217 34.2888 13.3333 33.4188 13.3333H6.58049C5.71049 13.3333 5.00049 12.6217 5.00049 11.7517V11.5817C5.00049 10.7117 5.71049 10 6.58049 10ZM33.4188 18.3333H6.58049C5.71049 18.3333 5.00049 19.045 5.00049 19.915V20.085C5.00049 20.955 5.71049 21.6667 6.58049 21.6667H33.4188C34.2888 21.6667 35.0005 20.955 35.0005 20.085V19.915C35.0005 19.045 34.2888 18.3333 33.4188 18.3333ZM33.4188 26.6667H6.58049C5.71049 26.6667 5.00049 27.3783 5.00049 28.2483V28.4183C5.00049 29.2883 5.71049 30 6.58049 30H33.4188C34.2888 30 35.0005 29.2883 35.0005 28.4183V28.2483C35.0005 27.3783 34.2888 26.6667 33.4188 26.6667Z" fill="#121212" />
                    </svg>
                    <button onClick={showModal} className="rounded-[20px] bg-transparent border border-[#D0D5DD] px-4 py-[10px]">
                        <div className="flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14.7 6.29974C14.5168 6.48666 14.4141 6.73798 14.4141 6.99974C14.4141 7.26149 14.5168 7.51281 14.7 7.69974L16.3 9.29974C16.4869 9.48296 16.7382 9.58559 17 9.58559C17.2617 9.58559 17.513 9.48296 17.7 9.29974L21.47 5.52974C21.9728 6.64092 22.1251 7.87897 21.9064 9.07888C21.6878 10.2788 21.1087 11.3836 20.2463 12.246C19.3838 13.1084 18.279 13.6876 17.0791 13.9062C15.8792 14.1248 14.6412 13.9726 13.53 13.4697L6.61998 20.3797C6.22215 20.7776 5.68259 21.0011 5.11998 21.0011C4.55737 21.0011 4.0178 20.7776 3.61998 20.3797C3.22215 19.9819 2.99866 19.4423 2.99866 18.8797C2.99866 18.3171 3.22215 17.7776 3.61998 17.3797L10.53 10.4697C10.0271 9.35855 9.87489 8.1205 10.0935 6.92059C10.3121 5.72068 10.8913 4.61589 11.7537 3.75346C12.6161 2.89102 13.7209 2.3119 14.9208 2.09328C16.1207 1.87465 17.3588 2.0269 18.47 2.52974L14.71 6.28974L14.7 6.29974Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="text-sm text-[#344054]">Repair your device</p>
                        </div>
                    </button>
                </div>

                {/* Web view */}
                <div className="flex gap-8 mb-10 overflow-x-auto">
                    <div className='bg-primary-300 text-white min-w-[372px] lg:w-full flex rounded-l-[20px] rounded-tr-[20px]'>
                        <div className="flex flex-col pl-4 pt-[26px] pb-[22px]">
                            <div className='flex flex-col'>
                                <p className='text-base lg:text-xl font-bold'>Secure and protect your device all round.</p>
                                <p className='text-sm lg:text-base font-medium'>Insure your devices with your preferred plan.</p>
                            </div>
                            <div className="flex justify-start">
                                <button className='bg-white mt-4 text-primary-300 rounded-3xl py-2 px-[14px]'>
                                    <div className="flex items-center gap-2">
                                        <p className='text-sm font-semibold'>Get care plan</p>
                                        <FaArrowRight />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <img src="/shield.png" alt="shield" />
                    </div>

                    <div className='bg-white text-primary-300 min-w-[372px] lg:w-full border-[1.5px] border-primary-300 flex rounded-l-[20px] rounded-tr-[20px]'>
                        <div className="flex flex-col pl-4 pt-[26px] pb-[22px]">
                            <div className='flex flex-col'>
                                <p className='text-base lg:text-xl font-bold'>Register your device.</p>
                                <p className='text-sm lg:text-base font-medium'>Register and link your devices to your protection plan</p>
                            </div>
                            <div className="flex justify-start">
                                <button className='bg-primary-200 mt-4 text-white rounded-3xl py-2 px-[14px]'>
                                    <div className="flex items-center gap-2">
                                        <p className='text-sm font-semibold'>Register your device</p>
                                        <FaArrowRight />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <img src="/mobile-hand.png" alt="shield" className='mt-3' />
                    </div>


                </div>



                <div className="flex flex-col gap-[14px]">
                    <div className="flex gap-[10px] items-center">
                        <p className='text-lg text-primary-300 font-medium'>Get a quick fix</p>
                        <Flash size="20" color="#fe7d3b" variant="Bold" />
                    </div>

                    <div className="flex gap-2 mb-14 overflow-x-auto">
                        {quickFixes.map((quickFix, index) => (
                            <div className={`flex cursor-pointer items-center rounded-[9px] border border-[#C0D8FB] p-4 ${(index === 0 || index === 1) ? "gap-20" : "gap-6"}`} key={index}>
                                <div className="flex gap-2 items-center">
                                    <img src={quickFix.image} alt='image' className='w-10 h-10' />
                                    <p className='text-primary-400 text-base font-semibold'>{quickFix.title}</p>
                                </div>
                                <ArrowRight2 size="20" color="#1f1844" />
                            </div>
                        ))}
                    </div>

                    <RepairTable tableData={tableData} ref={childRef} tableLoadingProgress={tableLoadingProgress} user={user} />
                </div>
            </>
        </Layout>
    )
}

export default ActiveOrders
// export default withRouter(ActiveOrders)
