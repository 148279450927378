import { ArrowRight } from "iconsax-react";
import Button from "./Button";
import FormInput from "./FormInput";

export default function FaultyDevice({goToNext, setComplaint, complaint}) {
    return (
        <div className="flex flex-col lg:w-[848px]">
            <p className="text-primary-300 text-[40px] font-semibold mb-8">Do you have a <span className="text-[#98A2B3]">faulty</span> device?</p>
            <div className="flex flex-col mb-10">
                <FormInput setValue={setComplaint} value={complaint} label={'What is wrong with it? '} type={'text'} placeholder={'example: my screen got broken'} />
            </div>

            <div className="flex w-full">
                <div className="">
                    <Button onButtonClick={goToNext} disabled={!complaint}>
                        <div className="flex text-white gap-2 items-center">
                            <p className="text-base font-semibold">Continue</p>
                            <ArrowRight size="20" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}