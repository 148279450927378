import React, { useEffect } from 'react'
import { ButtonBase, Container, Grid, Link, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Delete, DeleteOutline, DeleteSweep, Edit, Settings } from '@material-ui/icons';
import { useState } from 'react';
import RightbarNav from '../../RightBarNav';
import Leftbar from '../../Leftbar';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import './styles.css';
import { formatDate } from '../../../helpers/date';

const useStyles = makeStyles((theme)=> ({
    userListDelete: {
        color: "red",
        cursor: "pointer",
        fontSize: 30
    },
    
    userListEdit: {
        color: "#085279",
        border: "none",
        fontSize: 40,
        backgroundColor: "#fff",
        cursor: "pointer",
        marginRight: 20
    },

    button:{
        backgroundColor: "#E56F09",
        borderRadius: "20px",
        width: "170px",
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 18,
        "text-align": "center",
        fontWeight: "500",
    },
})); 

const OrderHistory = ({user}) => {
    
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    const [orders, setOrders] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        const fetchOrders = async()=>{
            try {
                const res = await axios.get(`${baseUrl}/Order/Customer/${user.id}`);
                if (res.data.success === true) {
                    setOrders(res.data.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchOrders();
    }, [])

    const rows = orders.map((order) => {
        let orderId = order.id;
        let orderRef = order.guid;
        let orderStatus =  order.status;
        let orderDate =  formatDate(order.orderDate);

        return { orderId, orderRef, orderStatus, orderDate};
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="orderHistoryPage">
            <div className="menusidebar">
                <Leftbar/>
            </div> 
            <div className="orderHistoryFeed">
                <RightbarNav pageTitle = "Order History"/>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="">Order ID</TableCell>
                                <TableCell className="">Order Status</TableCell>
                                <TableCell className="">Order Date</TableCell>
                                {/* <TableCell className="">View Details</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow key={row.orderId}>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="body2">{row.orderRef}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="body2">{row.orderStatus}</Typography>
                                    </TableCell>
                                    <TableCell>{row.orderDate}</TableCell>
                                    {/* <TableCell>
                                        <button className="renderInfoButton" onClick={() => displayUserModal(row)}>View Details</button>
                                    </TableCell> */}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <div className="orderCountDiv">
                     <p className={ classes.button }>Total Orders: {orders.length}</p>
                </div> 
            </div>
        </div>
    )
}

export default OrderHistory
