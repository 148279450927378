import { Paper } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import axiosInstance from '../../../helpers/axios';
import { formatDate } from '../../../helpers/date';
import { formatTimeFromDate } from '../../../helpers/timeFromDate';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import "./notifications.css";

const Notifications = ({user, toggleLeftBar, setToggleLeftBar}) => {
    
    const baseUrl = "https://uzomacharles.bsite.net/api/v1";
    const [notifications, setNotifications] = useState([]);
    const [currentNotificationId, setCurrentNotificationId] = useState(false);
    const [readNotificationsCount, setReadNotificationsCount] = useState(0);
    const [notificationsLoadingProgress, setNotificationsLoadingProgress] = useState(true);

    useEffect(() => {
        const fetchNotifications = async()=>{
            try {
                const res = await axiosInstance.get(`/Notification/${user.id}`);
                if (res.data.success === true) {
                    setNotificationsLoadingProgress(false);
                    setNotifications(res.data.data);
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchNotifications();
        
    }, [readNotificationsCount])


    async function markNotificationAsRead(notificationId){
        try {
            const res = await axiosInstance.post(`/Notification/MarkAsRead`, {
                userId: user.id,
                notifactionId: notificationId, 
            }, {headers: {'Content-Type': 'Application/json'}});

            if (res.status === 200) {
                setReadNotificationsCount((c) => c + 1); 
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleShowNotification(notification) {
        setCurrentNotificationId(notification.id);
        if (!notification.isRead) {
            markNotificationAsRead(notification.id);
        }
    }

    //reset sidebar
    useEffect(() => {
        setToggleLeftBar(false);
    }, [])

    return (
        <div className="notificationsPage">
           <div className="menusidebar">
                <Leftbar />
            </div> 
            <div className="mobilemenusidebar" style={ toggleLeftBar ? {width: "200px"} : undefined}>
                <Leftbar toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
            </div>
            <div className="notificationsFeed">
                <RightbarNav pageTitle = "Notifications" toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    {/* <div className="notificationsDiv"> */}
                        <div className="notificationSubFeed">
                                {
                                    notificationsLoadingProgress ? (
                                        <div className="notificationsLoadingProgressDiv">
                                        <BeatLoader color={"#1C1045"} loading={notificationsLoadingProgress} size={15} margin={2} /> 
                                        </div>
                                    ) :
                                    notifications.map((notification) => {
                                        return (
                                            <div className="notificationsDiv" 
                                             style={!notification.isRead ? {backgroundColor: '#F0F8FF'}: {backgroundColor: '#f8f8ff'}}
                                            >
                                                <div className="notificationTitle" 
                                                    key={notification.id} 
                                                    onClick={() => handleShowNotification(notification)}
                                                >
                                                    <h5 className="notificationTitleText">{notification.title}</h5>
                                                    <div style={{display: "flex", alignItems: "center", paddingBottom: "5px"}}>
                                                        <EventIcon fontSize="small"/> 
                                                        <h6 className="notificationTitleDate">{formatDate(notification.date)}</h6>
                                                    </div>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <ScheduleIcon fontSize="small"/>
                                                        <h6 className="notificationTitleDate">{formatTimeFromDate(notification.date)}</h6>
                                                    </div>
                                                </div>
                                                <div className="notificationBody">
                                                    <p style={notification.id !== currentNotificationId ? {display: "none"}: undefined}>
                                                        {notification.body}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }                   
                        </div>
                    {/* </div> */}
                </Paper>
            </div>
        </div>
    )
}

export default Notifications
