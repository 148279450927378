import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Select from 'react-select';

export default function FormInput({ label, setValue, value, type, options, placeholder, isDarkMode }) {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    switch (type) {
        case 'text': {
            return (
                <div className="flex flex-col gap-[6px]">
                    <p className={`${isDarkMode ? "text-white" : "text-primary-400"} font-medium`}>{label}</p>
                    <div className={`border py-[10px] flex px-[14px] rounded-lg ${isDarkMode ? "border-[#F9FAFB]" : "border-[#D0D5DD]"}`}>
                        <input className={`w-full outline-none bg-transparent p-0 text-base ${isDarkMode ? "text-white" : "text-primary-100"}`} placeholder={placeholder} type={type} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
            )
        }

        case 'password': {
            return (
                <div className="flex flex-col gap-[6px]">
                    <p className={`${isDarkMode ? "text-white" : "text-primary-400"} font-medium`}>{label}</p>
                    <div className={`border py-[10px] flex px-[14px] items-center rounded-lg ${isDarkMode ? "border-[#F9FAFB]" : "border-[#D0D5DD]"}`}>
                        <input className={`w-full outline-none bg-transparent p-0 text-base ${isDarkMode ? "text-white" : "text-primary-100"}`} type={passwordShown ? 'text' : 'password'} value={value} onChange={(e) => setValue(e.target.value)} />
                        <div
                            className="cursor-pointer"
                            onClick={togglePasswordVisiblity}
                        >
                            {passwordShown ? <FiEyeOff color={`${isDarkMode && "#FFF"}`}/> : <FiEye color={`${isDarkMode && "#FFF"}`} />}
                        </div>
                    </div>
                </div>
            )
        }

        case 'select': {
            return (
                <div className="flex flex-col gap-[6px]">
                    <p className="text-primary-400 font-medium">{label}</p>
                    <Select
                        value={value}
                        onChange={(e) => setValue(e)}
                        options={options}
                    />
                </div>
            )
        }

        case 'textarea': {
            return (
                <div className="flex flex-col gap-[6px]">
                    <p className="text-primary-400 font-medium">{label}</p>
                    <div className="border py-[10px] px-[14px] rounded-lg border-[#D0D5DD]">
                        <textarea placeholder={placeholder} className="w-full outline-none p-0 text-base text-primary-100" rows={5} type={type} value={value} onChange={(e) => setValue(e.target.value)} ></textarea>
                    </div>
                </div>
            )
        }
    }
}