import './feedbackpage.css';
import React, { useEffect } from 'react';
import { makeStyles,  Typography } from '@material-ui/core';
import { ArrowForward, Email, LocationOn } from '@material-ui/icons';
import { useState } from 'react';
import RightbarNav from '../../RightBarNav';
import Leftbar from '../../Leftbar';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { formatDate } from '../../../helpers/date';


const useStyles = makeStyles((theme)=> ({

    container: {
        paddingTop: 10,
        border: "solid 1px #C0C0C0",
        borderRadius: "0px 0px 8px 8px",
        paddingBottom: 20,
    },

    
    formItems: {
        width: "100",
        marginBottom: "30px",
        paddingLeft: "30px",
        paddingRight: "30px"
    },

    formbaseIcon: {
        size: "20px",
        marginRight: 10,
        paddingTop: 3,
    },

    
})); 

const FeedbackPage = ({user, toggleLeftBar, setToggleLeftBar}) => {

    const classes = useStyles();     
    const baseUrl = "https://uzomacharles.bsite.net/api/v1";

    const [feedBackBody, setFeedBackBody] = useState("");
    const [userOrders, setUserOrders] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState("");
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("")

    // FETCH CUSTOMER ORDERS
    useEffect(() => {
        const fetchUserOrders = async()=>{
            try {
                const res = await axios.get(`${baseUrl}/Order/Customer/${user.id}`);
                if (res.data.success === true) {
                    setUserOrders(res.data.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchUserOrders();
        
    }, []);

    
    const handleSubmitFeeback =  async(e)=> {
        e.preventDefault();
        setLoadingProgress(true);
        try {
            const res = await axios.post(`${baseUrl}/Feedback`, {
                body: feedBackBody,
                orderId: parseInt(selectedOrderId),
                userId: parseInt(user.id),
              },{headers: {'content-type': 'application/json', 'Access-Control-Allow-Origin': '*'}});
            
            if (res.data.success === true) {
                errorMessage ? setErrorMessage("") : 
                setLoadingProgress(false);
                setResponseMessage((<Typography variant="h6" className="successMessage">{res.data.info}</Typography>)); 
                setSelectedOrderId("");
                setFeedBackBody("");
                setTimeout(() => {
                    window.location.href = "/feedback";
                }, 2000);
            }
        } catch (error) {
            console.log(error)
            setLoadingProgress(false);
            setResponseMessage((<Typography variant="h6" className="errorMessage">An Error has occured, please try again later</Typography>));  
            setTimeout(() => {
                setErrorMessage("");
            }, 5000);
        } 
    }
    
    useEffect(() => {
        setToggleLeftBar(false);
    }, [])
    
    return (
        <div className="feedBackPage">
            <div className="menusidebar" id={ toggleLeftBar ? "mobilemenusidebar" : ""}>
                <Leftbar toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
            </div> 
            <div className="feedBackFeed">
                    <RightbarNav pageTitle = "Feedback Page" toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
                    <div className="feedBackMContainer">
                        <h4 className="topInformation">Fill out the form and we'll be in touch very soon </h4>
                        
                        <div>
                            { responseMessage ? responseMessage :  ''}
                      </div>

                        <form className="formDiv" onSubmit={handleSubmitFeeback}>                                
                            <div className= {classes.formItems}>
                                <select  className='formSelect' value={selectedOrderId} onChange={(e) => setSelectedOrderId(e.target.value)} >
                                    <option value={0}>Order ID</option>
                                    {
                                        userOrders.map((order)=>{
                                        return (
                                            <option value={order.id}>{`${order.guid.substring(0, 10)}---${formatDate(order.orderDate)}`}</option>
                                        )}
                                    )}
                                </select>
                            </div>
                            <div className= {classes.formItems}>
                                <textarea name="customercomplaint" id="" cols="80" rows="10" className="formTextArea" value={feedBackBody} onChange={(e) => setFeedBackBody(e.target.value)}>
                                </textarea>
                            </div>

                            <div className="baseSendButtonDiv">
                                <button className="baseSendButton">
                                    { !loadingProgress ? (<div className="baseButtonItems">
                                       Send <ArrowForward className="baseButtonIcon"></ArrowForward>
                                    </div>) : (<BeatLoader color={"#1C1045"} loading={loadingProgress} size={15} margin={2} />) }
                                </button>
                            </div>
                        </form> 
                    </div>
            </div>
        </div>
    )
}

export default FeedbackPage

