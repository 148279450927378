export default function     EmptyRepair({onButtonClick}) {
    return (
        <div className="flex flex-col justify-center py-10 items-center">
            <img src="/phone-repair.svg" alt="phone-repair" className="w-[82.476px] lg:w-[117px] mb-4 h-[97.94px] lg:h-[138.938px]"/>

            <div className="flex flex-col text-center">
                <p className="text-base font-semibold">Start by requesting a repair</p>
                <p className="text-sm text-primary-100">Your repair orders will be tracked from here</p>
            </div>

            <button onClick={onButtonClick} className='bg-primary-300 rounded-[30px] mt-6 text-white text-sm font-semibold  p-2 md:p-3 rounded-[20px]'>
                Repair device
            </button>
        </div>
    )
}