import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import './styles.css';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import axiosInstance from '../../../helpers/axios';

const useStyles = makeStyles((theme)=>({
    formContainer:{
        height: "100vh",
        color: "white",
        paddingTop: 20,
        flex: 2, 
        backgroundColor: "#1C1045",
        top: 0, 
        marginBottom: 0,
    },
    logoGrp:{
        marginBottom: "30px",
        marginTop: "-20px",
    },
    logoLg: {
        display: "block",
        maxWidth: "45%",
        height: "auto",
    },
    
    form: {
        padding: theme.spacing(2),
    },
    item: {
        marginBottom: theme.spacing(4),
    },
    label:{
        color: "#E56F09",
    },
    borderbottom: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E56F09"
        },
     },
    textfield:{
        color: "#1C1045",
    },
  
}));
const Profile = ({user, toggleLeftBar, setToggleLeftBar}) => {

    const classes = useStyles();
    
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [activateEditeDetails, setActivateEditeDetails] = useState(false);
    const [fullName, setFullName] = useState(user.fullName);
    const [address, setAddress] = useState(user.address);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
    const [email, setEmail] = useState(user.email);
    const [responseMessage, setResponseMessage] = useState("");

    const enableEditDetails = () => {
      setActivateEditeDetails(true);
    }

    const handleUpdateProfile = async(e) => {
        e.preventDefault();
        if (!loadingProgress) {
          setLoadingProgress(true);
        }
        const userUpdated = {
          id: user.id,
          fullName: fullName,
          phoneNumber: phoneNumber,
          address: address,
          email: email
        } 
        try {
          const res = await axiosInstance.post(`/User/UpdateDetails`, userUpdated, {headers: {'Content-Type': 'application/json'}});
          if (res.data.success === true) {
            localStorage.setItem('user', JSON.stringify(res.data.data));
            setLoadingProgress(false);
            setResponseMessage(<Typography variant="h6" className="successMessage">{res.data.info}</Typography>)
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
      } catch (error) {
        setLoadingProgress(false);
        setResponseMessage(<Typography variant="h6" className="errorMessage">An Error has occured, please try again later</Typography>)
      } 
    }

    // function handleGoBack(){
    //    history.goBack()
    // }

    //reset sidebar
    useEffect(() => {
      setToggleLeftBar(false);
  }, [])

    return (
            <div container className="profileContainer">
                <div className="menusidebar">
                  <Leftbar/>
                </div>

                <div className="mobilemenusidebar" style={ toggleLeftBar ? {width: "200px"} : undefined}>
                  <Leftbar toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
                </div>

                  <div className="updateProfileFeed">
                    <RightbarNav pageTitle = "View Your Profile Details" toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar} />
                    <div className="profileRequestContainer">

                    <h4 className="mobileViewPageIdentifier">Update Your Profile</h4>                      
                      <div className="editDetailsContainer" style={{marginTop:"10px", width: "100px", paddingLeft: "10px" }}>
                          {/* <button className="editDetailsActivate" onClick={enableEditDetails} disabled ={activateEditeDetails}>Enable</button> */}
                      </div>
                      <div>
                            { responseMessage ? responseMessage :  ''}
                      </div>


                        <form action="" className="formDiv" onSubmit={handleUpdateProfile}>
                              <div className={ classes.item }>
                              {/* <label for="fname">Full name</label> */}
                                  {/* <input type="text"  id="" autoComplete="off" className="formInput" disabled={!activateEditeDetails} value={fullName}  */}
                                  <input type="text"  id="" autoComplete="off" className="formInput" value={fullName} 
                                  onChange={(e) => setFullName(e.target.value)}/>
                              </div>
                              <div className={ classes.item }>
                                  <input type="text" id="" autoComplete="off" className="formInput"  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)}/>
                              </div>
                              {/* <div className={ classes.item }>
                                  <input type="text" disabled={!activateEditeDetails} id="" autoComplete="off" className="formInput" value={email} 
                                  onChange={(e) => setEmail(e.target.value)}/>
                              </div> */}
                              <div className={ classes.item }>
                                <textarea name="" id="" cols="80" rows="7" className="addressTextArea" value={address} 
                                    onChange={(e) => setAddress(e.target.value)}> </textarea>
                              </div>
                              
                             <div className="buttonDiv">
                              <button className="submitButton">
                                { !loadingProgress ? 'Save' :  (<BeatLoader color={"#1C1045"} loading={loadingProgress} size={15} margin={2} />)} 
                                </button> 
                             </div>
                          </form>
                    </div>
                  </div>
            </div>
    )
}

export default Profile
