import { Modal } from "antd";
import Button from "./Button";

export default function ConfirmationModal(
    { open, hideModal, secondaryButtonText, primaryButtonText, secondaryButtonClick, primaryButtonClick, mainTitle, subTitle }) {
    return (
        <Modal
            open={open}
            onCancel={hideModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}>
            <div className="flex font-inter w-full flex-col justify-center">
                <div className="flex justify-center p-0 mb-5">
                    <div className="border-[#ECFDF3] bg-[#D1FADF] rounded-[39px] border-8">
                        <img src='circle.svg' alt="circle" />
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center gap-2 mb-8">
                    <p className="font-semibold text-lg text-primary-400">{mainTitle}</p>
                    <p className="text-sm text-primary-100">{subTitle}</p>
                </div>

                <div className="flex gap-3">
                    <Button isSecondary={true} onButtonClick={secondaryButtonClick}>
                        {secondaryButtonText}
                    </Button>
                    <Button onButtonClick={primaryButtonClick}>
                        {primaryButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}