import { useState } from "react";
import DeviceType from "../../DeviceType";
import FaultyDevice from "../../FaultyDevice";
import RepairOption from "../../RepairOption";
import { useHistory } from 'react-router';

export default function Onboarding() {
    const history = useHistory();
    const [page, setPage] = useState(0)
    const [deviceType, setDeviceType] = useState('')
    const [complaint, setComplaint] = useState('')
    const [isHomePickup, setIsHomePickup] = useState(true)

    function next() {
        setPage(page + 1)
    }
    function back() {
        setPage(page - 1)
    }

    function sumbitOrder() {
        const orderReq = {
            deviceType,
            complaint,
            isHomePickup
        }
        localStorage.setItem('order', JSON.stringify(orderReq))
        localStorage.setItem('isFirstTime', 'false')
        history.push('/signup')
    }

    return (
        <div>
            <div class="flex w-full justify-center items-center flex-col bg-[url('https://i.ibb.co/hLJYtqB/ob-1.png')]">
                <div
                    class="w-full px-6 lg:px-24">
                    {/* Web view */}
                    <div class="w-full h-full lg:flex justify-between hidden">
                        <div className="flex flex-col pt-6">
                            <img src="/logo-white.svg" alt="log-white" className="w-[151.17px] h-[38.71px] mb-[50px]" />

                            <div className="flex flex-col text-white font-poppins gap-6">
                                <p className="text-5xl font-semibold">Quality repair at your convenience</p>
                                <p className="text-2xl">Share a few details, and we'll provide the optimal fix for you.</p>
                            </div>
                        </div>
                        <div className="pt-4">
                            <img src="onboard.svg" alt="onboard" />
                        </div>
                    </div>

                    {/* Mobile view */}
                    <div className="flex flex-col lg:hidden mb-6">
                        <div className="flex w-full  mb-[0px] mt-5  ">
                            <img src="/logo-white.svg" alt="log-white" className="w-[151.17px] h-[38.71px] mb-[50px]" />

                            <div className="w-full justify-end mt-[-15px]">
                                <img src="onboard.svg" alt="onboard" className="w-[71px] h-[65px] float-right" />
                            </div>
                        </div>
                        <div className="flex flex-col text-white font-poppins gap-3">
                            <p className="text-[21px] font-semibold">Quality repair at your convenience</p>
                            <p className="text-base">Share a few details, and we'll provide the optimal fix for you.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Info */}
            <div className="flex font-inter w-full justify-center pt-[39px] px-6 lg:pt-[72px]">
                {page === 0 && <FaultyDevice goToNext={next} setComplaint={setComplaint} complaint={complaint}/>}
                {page === 1 && <DeviceType goBack={back} goToNext={next} setDeviceType={setDeviceType} deviceType={deviceType}/>}
                {page === 2 && <RepairOption goBack={back} setIsHomePickup={setIsHomePickup} isHomePickup={isHomePickup} onSubmit={sumbitOrder}/>}
            </div>
        </div>
    )
}