import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import axiosInstance from '../../../helpers/axios';
import "./orderdetails.css";
import { BeatLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { Modal, message } from 'antd';
import { ArrowLeft, ArrowRight2, DocumentCopy } from 'iconsax-react';
import Button from '../../Button';
import UnavailableModal from '../../UnavaibleModal';
import copy from "copy-to-clipboard";
import Layout from '../../Layout';
import parse from 'html-react-parser';

const OrderDetailsPage = ({ user, toggleLeftBar, setToggleLeftBar }) => {
    const history = useHistory();
    const location = useLocation();
    const orderId = location.pathname.split("/")[3];
    const [orderDetails, setOrderDetails] = useState({ order: {}, invoices: [], diagnosis: '' });
    const [pageLoadingProgress, setPageLoadingProgress] = useState(true);
    const [diagnosisInvoices, setDiagnosisInvoices] = useState([]);

    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [openBankTransferModal, setOpenBankTransferModal] = useState(false);
    const [isCouplingBack, setIsCouplingBack] = useState(false);
    const [openMoreOptionsModal, setOpenMoreOptionsModal] = useState(false);
    const [openUnavailableModal, setOpenUnavailableModal] = useState(false);
    const [canProceed, setCanProceed] = useState(false);
    const [openPaymentOptionsModal, setOpenPaymentOptionsModal] = useState(false);
    const [isDirectBankTransfer, setIsDirectBankTransfer] = useState(true);
    const [openDiagnosis, setOpenDiagnosis] = useState(false);
    const [openDeviceIssue, setOpenDeviceIssue] = useState(false);
    const [showDiscount, setShowDiscount] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [customerOrdersCount, setCustomerOrdersCount] = useState(0);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const res = await axiosInstance.get(`/Order/OrderDetails/${orderId}`);
                if (res.data.success === true) {
                    if (res.data.data.invoices.length > 0) {
                        let sum = 0
                        res.data.data.invoices.forEach((invoice) => {
                            if (invoice.isDiagnosis) {
                                return
                            }
                            sum += Number(invoice.amount)
                        })
                        setTotalAmount(sum)
                    }
                    setOrderDetails(res.data.data)
                }
            } catch (error) {
                console.log(error)
            }
            setPageLoadingProgress(false);
        }
        fetchOrderDetails();

    }, []);

    useEffect(() => {
        const fetchOrdersCount = async () => {
            try {
                const res = await axiosInstance.get(`/Order/Customer/${user.id}/Count`);
                if (res.data.success === true) {
                    setCustomerOrdersCount(Number(res.data.data))
                    if (Number(res.data.data) > 1) {
                        return;
                    }

                    if (orderDetails.invoices.length === 1 && orderDetails.invoices[0].isDiagnosis) {
                        return
                    }

                    const theDiscount = totalAmount * 0.1
                    setDiscount(theDiscount)
                    setTotalAmount(totalAmount - theDiscount)
                    setShowDiscount(true)

                }
            } catch (error) {
                console.log(error)
            }
        }

        if (orderDetails.invoices.length > 0 && totalAmount > 0) {
            fetchOrdersCount()
        }
    }, [orderDetails])

    const handleCopyAccountNumber = () => {
        copy('7896734512');
        message.success('Copy to clipboard successfully');
    }

    function goBack() {
        history.goBack();
    }

    const handleUseDiscount = () => {
        console.log(customerOrdersCount)
        if (customerOrdersCount > 1 || showDiscount) {
            closeMoreOptions()
            return;
        }

        if (orderDetails.invoices.length === 1 && orderDetails.invoices[0].isDiagnosis) {
            closeMoreOptions()
            return
        }

        const theDiscount = totalAmount * 0.2
        setDiscount(theDiscount)
        setTotalAmount(totalAmount - theDiscount)
        setShowDiscount(true)
        closeMoreOptions()
    }

    const handleCoupleBack = () => {
        setIsCouplingBack(true)
        const theDiscountInvoices = []
        let sum = 0;
        orderDetails.invoices.forEach((invoice) => {
            if (invoice.isDiagnosis) {
                theDiscountInvoices.push(invoice)
                sum += Number(invoice.amount)
            }
        })

        setTotalAmount(sum)
        setDiagnosisInvoices(theDiscountInvoices)
        // closeMoreOptions()
    }

    const handleProceedToPayment = async () => {
        debugger
        if (isDirectBankTransfer) {
            handleOpenBankTransferModal()
            return;
        }

        try {
            const res = await axiosInstance.post(`/Payment/Initialize`, {
                email: orderDetails.order.customer.email,
                order_ID: orderDetails.order.guid,
                isDiagnosis: true
            });
            if (res.status === 200) {
                // setButtonLoadingProgress(false);
                window.location.href = res.data.payment_Url;
            }
        } catch (error) {

        }
    }

    const handleOpenBankTransferModal = () => {
        setOpenBankTransferModal(true);
    }

    const handleOpenInvoiceModal = () => {
        setOpenInvoiceModal(true);
    }

    const handlePaymentOptionsModal = () => {
        console.log(orderDetails.order);
        setOpenPaymentOptionsModal(true);
    }

    const handlOpenDiagnosisModal = () => {
        setOpenDiagnosis(true);
    }

    const handlOpenDeviceIssueModal = () => {
        setOpenDeviceIssue(true);
    }

    const handlOpenMoreOptions = () => {
        setOpenMoreOptionsModal(true);
    }

    const handleOpenUnavailableModal = () => {
        setOpenUnavailableModal(true);
    }

    const closeDiagnosisModal = () => {
        setOpenDiagnosis(false);
    }

    const closeDeviceIssueModal = () => {
        setOpenDeviceIssue(false);
    }

    const closeCoupleBackModal = () => {
        setIsCouplingBack(false);
    }

    const closeInvoiceModal = () => {
        setOpenInvoiceModal(false);
    }

    const closeBankTransferModal = () => {
        setOpenBankTransferModal(false);
    }

    const closeMoreOptions = () => {
        setOpenMoreOptionsModal(false);
    }

    const closeUnavailableModal = () => {
        setOpenUnavailableModal(false);
    }

    const closePaymentOptionsModal = () => {
        setOpenPaymentOptionsModal(false);
    }

    function getStatusChip(order) {
        switch (order.orderStatusId) {
            // switch (orderStatusId) {
            case 1:
                return (
                    <div className='bg-[#EEF3FF] rounded-2xl text-[#114FEE] text-xs text-center px-2 py-1'>
                        {order.status}
                    </div>
                );
            case 2:
                return (
                    <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                        {order.status}
                    </div>
                );
            case 5:
                return (
                    <div className='bg-[#ECFDF3] rounded-2xl text-[#027A48] text-xs text-center px-2 py-1'>
                        {order.status}
                    </div>
                );
        }
    }

    return (
        <Layout pageTitle={'Repair'} user={user}>
            <>
                {/* Diagnosis modal */}
                <Modal open={openDiagnosis}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    onCancel={closeDiagnosisModal}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#FEF3F2] bg-[#FEE4E2] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_556_9684)">
                                        <path d="M8 5.33325V7.99992M8 10.6666H8.00667M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6818 1.33333 7.99992C1.33333 4.31802 4.3181 1.33325 8 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_556_9684">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Diagnosis</p>
                        </div>

                        <div className="mt-6 text-primary-100 text-lg">
                            {orderDetails.diagnosis ? parse(orderDetails.diagnosis.summary) : 'No diagnosis report has been added'}
                        </div>
                    </div>
                </Modal>

                {/* Device issue modal */}
                <Modal open={openDeviceIssue}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    onCancel={closeDeviceIssueModal}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#FFFAEB] bg-[#FEF0C7] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.99986 5.33339V8.00006M7.99986 10.6667H8.00653M6.85986 1.90672L1.21319 11.3334C1.09677 11.535 1.03517 11.7636 1.03452 11.9964C1.03387 12.2292 1.09419 12.4581 1.20948 12.6604C1.32477 12.8627 1.49101 13.0312 1.69167 13.1493C1.89232 13.2674 2.12039 13.3308 2.35319 13.3334H13.6465C13.8793 13.3308 14.1074 13.2674 14.3081 13.1493C14.5087 13.0312 14.675 12.8627 14.7902 12.6604C14.9055 12.4581 14.9659 12.2292 14.9652 11.9964C14.9646 11.7636 14.903 11.535 14.7865 11.3334L9.13986 1.90672C9.02101 1.71079 8.85368 1.5488 8.65399 1.43638C8.45431 1.32395 8.22902 1.26489 7.99986 1.26489C7.7707 1.26489 7.54542 1.32395 7.34573 1.43638C7.14605 1.5488 6.97871 1.71079 6.85986 1.90672Z" stroke="#DC6803" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Device Issue</p>
                        </div>

                        <div className="mt-6 text-primary-100 text-lg">
                            {orderDetails?.order?.device?.complaint}
                        </div>
                    </div>
                </Modal>

                {/* Other options modal */}
                <Modal open={openMoreOptionsModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    onCancel={closeMoreOptions}
                >
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <p className='text-lg text-primary-400 font-medium'>Other payment options</p>
                        </div>

                        <div onClick={handleOpenUnavailableModal} className="flex cursor-pointer border-b-[0.5px] border-b-[#F2F4F7] items-center py-2 justify-between mt-4">
                            <div className="flex flex-col gap-1">
                                <p className="text-sm text-primary-400 font-medium">Pay with <span className='font-goldman font-bold text-[#F04301]'>TB</span><span className='font-dmsans text-[#F04301]'>repair</span></p>
                                <p className="text-primary-100 text-sm">Register now and pay flexibly with 30% downpayment</p>
                            </div>
                            <ArrowRight2 size="16" color="#B4B2C1" />
                        </div>
                        <div onClick={handleUseDiscount} className="flex cursor-pointer border-b-[0.5px] border-b-[#F2F4F7] items-center py-2 justify-between mt-4">
                            <div className="flex flex-col gap-1">
                                <p className="text-sm text-primary-400 font-medium">Use discount</p>
                                <p className="text-primary-100 text-sm">10% discount on first repair</p>
                            </div>
                            <ArrowRight2 size="16" color="#B4B2C1" />
                        </div>
                        <div onClick={handleCoupleBack} className="flex cursor-pointer items-center py-2 justify-between mt-4">
                            <div className="flex flex-col gap-1">
                                <p className="text-sm text-[#D92D20] font-medium">Couple back</p>
                                <p className="text-primary-100 text-sm">You will be charged for diagnostics</p>
                            </div>
                            <ArrowRight2 size="16" color="#B4B2C1" />
                        </div>
                    </div>
                </Modal>

                <UnavailableModal open={openUnavailableModal} hideModal={closeUnavailableModal} />

                {/* Choose payment method modal */}
                <Modal open={openPaymentOptionsModal}
                    onCancel={closePaymentOptionsModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div>
                        <div className="flex justify-start">
                            <div className="border-[#FDF2EC] bg-[#FFD8C5] rounded-[39px] border-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g clip-path="url(#clip0_755_10786)">
                                        <path d="M0.833374 8.33331H19.1667M2.50004 3.33331H17.5C18.4205 3.33331 19.1667 4.07951 19.1667 4.99998V15C19.1667 15.9205 18.4205 16.6666 17.5 16.6666H2.50004C1.57957 16.6666 0.833374 15.9205 0.833374 15V4.99998C0.833374 4.07951 1.57957 3.33331 2.50004 3.33331Z" stroke="#1F1844" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_755_10786">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>

                        <div className="flex flex-col my-5 gap-2">
                            <p className='text-lg text-primary-400 font-semibold'>Choose payment method</p>
                            <p className="text-primary-100 text-sm">Select your preferred payment method.</p>
                        </div>

                        {/* Payment methods */}
                        {/* Bank Transfer */}
                        <div className="flex border group hover:bg-[#FFFCFA] hover:border-[#FFD8C5] cursor-pointer border-[#EAECF0] bg-[#F9FAFB] gap-3 items-start rounded-lg p-4" onClick={() => setIsDirectBankTransfer(true)}>
                            <div className="flex items-start">
                                <div className="border-[#F2F4F7] bg-[#F2F4F7] rounded-[39px] border-[6px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M1.66663 12.0833L9.99996 16.25L18.3333 12.0833M9.99996 3.75L1.66663 7.91667L9.99996 12.0833L18.3333 7.91667L9.99996 3.75Z" stroke="#D0D5DD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-primary-300 font-medium text-sm">Direct Bank transfer <span className='text-[#98A2B3] font-normal'>preferred</span> </p>
                                <p className="text-primary-100 text-sm group-hover:text-primary-300">Make payments directly by transferring to our bank account. </p>
                            </div>
                            <input type="radio" className='mt-1' checked={isDirectBankTransfer} />
                        </div>

                        {/* Card payment */}
                        <div className="flex border border-[#EAECF0] group mt-3 bg-[#F9FAFB] hover:bg-[#FFFCFA] hover:border-[#FFD8C5] cursor-pointer gap-3 items-start rounded-lg p-4 pb-9" onClick={() => setIsDirectBankTransfer(false)}>
                            <div className="flex items-start">
                                <div className="border-[#F2F4F7] bg-[#F2F4F7] rounded-[39px] border-[6px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M1.66663 12.0833L9.99996 16.25L18.3333 12.0833M9.99996 3.75L1.66663 7.91667L9.99996 12.0833L18.3333 7.91667L9.99996 3.75Z" stroke="#D0D5DD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <p className="text-primary-300 font-medium text-sm">Card payment</p>
                                <p className="text-primary-100 text-sm group-hover:text-primary-300">Pay with your credit or debit card</p>
                            </div>
                            <input type="radio" className='mt-1 float-right' checked={!isDirectBankTransfer} />
                            {/* <div className="w-full justify-end float-right">
                            </div> */}
                        </div>

                        <div className="flex gap-3 mt-8">
                            <Button isSecondary={true} onButtonClick={closePaymentOptionsModal}>
                                Cancel
                            </Button>
                            <Button onButtonClick={handleProceedToPayment}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </Modal>

                {/* Bank transfer modal */}
                <Modal open={openBankTransferModal}
                    onCancel={closeBankTransferModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div>
                        <div className="flex justify-start">
                            <div className="border-[#FDF2EC] bg-[#FFD8C5] rounded-[39px] border-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g clip-path="url(#clip0_755_10786)">
                                        <path d="M0.833374 8.33331H19.1667M2.50004 3.33331H17.5C18.4205 3.33331 19.1667 4.07951 19.1667 4.99998V15C19.1667 15.9205 18.4205 16.6666 17.5 16.6666H2.50004C1.57957 16.6666 0.833374 15.9205 0.833374 15V4.99998C0.833374 4.07951 1.57957 3.33331 2.50004 3.33331Z" stroke="#1F1844" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_755_10786">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>

                        <div className="flex flex-col my-5 gap-2">
                            <p className='text-lg text-primary-400 font-semibold'>Bank transfer</p>
                            <p className="text-primary-100 text-sm">Make transfer to the account details provided</p>
                        </div>

                        {/* Account info */}
                        <div className="flex bg-[#F9FAFB] gap-3 justify-between items-start rounded-lg p-4">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-1">
                                    <p className="text-sm text-primary-300 font-medium">Account number</p>
                                    <div className="flex items-center gap-2">
                                        <p className="text-sm text-[#8F8BA1]">7896734512</p>
                                        <DocumentCopy onClick={handleCopyAccountNumber} size="20" color="#8F8BA1" className='cursor-pointer' />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className="text-sm text-primary-300 font-medium">Account name</p>
                                    <p className="text-sm text-[#8F8BA1]">Techbase Nigeria</p>
                                </div>
                            </div>

                            <div className="flex flex-col justify-end gap-4">
                                <div className="flex flex-col gap-1">
                                    <p className="text-sm text-primary-300 font-medium">Bank name</p>
                                    <p className="text-sm text-[#8F8BA1]">Monie Point</p>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className="text-sm text-primary-300 font-medium">Amount to be paid</p>
                                    <p className="text-base font-bold text-[#F04301]">N{totalAmount.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z" fill="#3168F3" />
                            </svg>
                            <p className="text-sm text-[#3168F3]">click on confirm payment after making transfer successfully</p>
                        </div>

                        <div className="flex gap-3 mt-8">
                            <Button isSecondary={true} onButtonClick={closeBankTransferModal}>
                                Back
                            </Button>
                            <Button>
                                Confirm Payment
                            </Button>
                        </div>
                    </div>
                </Modal>

                {/* Invoice modal */}
                <Modal open={openInvoiceModal}
                    onCancel={closeInvoiceModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#EEF3FF] bg-[#1851e430] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.33325H6C5.632 9.33325 5.33334 9.03458 5.33334 8.66658C5.33334 8.29858 5.632 7.99992 6 7.99992H8C8.368 7.99992 8.66667 8.29858 8.66667 8.66658C8.66667 9.03458 8.368 9.33325 8 9.33325ZM5.33334 11.3333C5.33334 10.9653 5.632 10.6666 6 10.6666H10C10.3687 10.6666 10.6667 10.9653 10.6667 11.3333C10.6667 11.7013 10.3687 11.9999 10 11.9999H6C5.632 11.9999 5.33334 11.7013 5.33334 11.3333ZM11.6295 13.3333H4.3702C4.1662 13.3333 4.0002 13.1839 4.0002 12.9999V2.99992C4.0002 2.81592 4.1662 2.66659 4.3702 2.66659H8.0002V4.76659C8.0002 5.81459 8.81154 6.66658 9.80954 6.66658H12.0002V12.9999C12.0002 13.1839 11.8342 13.3333 11.6295 13.3333ZM9.33354 3.31859L11.1615 5.33325H9.80954C9.54687 5.33325 9.33354 5.07925 9.33354 4.76659V3.31859ZM13.1602 5.55192L9.53087 1.55192C9.4042 1.41259 9.22554 1.33325 9.03687 1.33325H4.3702C3.43087 1.33325 2.66687 2.08125 2.66687 2.99992V12.9999C2.66687 13.9186 3.43087 14.6666 4.3702 14.6666H11.6295C12.5689 14.6666 13.3335 13.9186 13.3335 12.9999V5.99992C13.3335 5.83392 13.2715 5.67459 13.1602 5.55192Z" fill="#1851E4" />
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Your Invoice</p>
                        </div>
                        {orderDetails.invoices.length > 0 ? <>
                            <div className="flex items-center gap-2 my-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z" fill="#98A2B3" />
                                </svg>
                                <p className="text-primary-100 text-base">Diagnostics fee will be eliminated if you are fixing in our base</p>
                            </div>

                            {/* Invoice info */}
                            <div className="rounded-lg">
                                {/* Column header */}

                                <div className="grid grid-cols-2 w-full bg-[#F4F8FD] border-b border-b-[#EAECF0] px-6 py-3 rounded-t-lg">
                                    <p className="text-primary-100 text-base">
                                        Description
                                    </p>
                                    <p className="text-primary-100 text-base text-right">
                                        Amount
                                    </p>
                                </div>

                                {/* Invoices */}
                                {orderDetails.invoices.map((invoice, index) => (<div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-6 py-4 border-b border-b-[#EAECF0]">
                                    <p className="text-primary-400 text-sm font-medium">
                                        {invoice.title}
                                    </p>
                                    <p className={`text-primary-400 text-sm text-right font-medium ${invoice.isDiagnosis ? "line-through" : ""}`}>
                                        N{Number(invoice.amount).toLocaleString()}
                                    </p>
                                </div>))}

                                {/* Discount */}
                                {showDiscount ? <div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-6 py-4 border-b border-b-[#EAECF0]">
                                    <p className="text-[#F04301] text-sm font-medium">
                                        Discount 10%
                                    </p>
                                    <p className="text-[#F04301] text-sm text-right font-medium">
                                        N{discount.toLocaleString()}
                                    </p>
                                </div> : null}

                                {/* Total cost */}
                                <div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-7 py-4 rounded-b-lg">
                                    <p className="text-primary-400 text-base font-semibold">
                                        Total
                                    </p>
                                    <p className="text-primary-400 text-base text-right font-semibold">
                                        N{totalAmount.toLocaleString()}
                                    </p>
                                </div>

                                <div className="w-full flex justify-end mt-[37px] gap-3">
                                    <button className="rounded-3xl bg-[#F9F5FF] py-2 px-[14px]" onClick={handlOpenMoreOptions}>
                                        <div className="flex items-center justify-center">
                                            <p className='text-sm font-semibold text-primary-300 mt-[-4px]'>More options</p>
                                            <ArrowRight2
                                                size="20"
                                                color="#292D32"
                                                variant="Bold"
                                            />
                                        </div>
                                    </button>
                                    <button disabled={totalAmount < 1 ||  orderDetails.order?.isPaid} className="rounded-3xl bg-primary-300 py-2 px-[14px]" onClick={handlePaymentOptionsModal}>
                                        <div className="flex items-center justify-center text-sm font-semibold text-white">
                                            Proceed to payment
                                        </div>
                                    </button>
                                </div>

                            </div>
                        </> : <div className="mt-6 text-primary-100 text-lg">
                            No invoice has been added
                        </div>}
                    </div>
                </Modal>

                {/* Couple back modal */}
                <Modal open={isCouplingBack}
                    onCancel={closeCoupleBackModal}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div>
                        <div className='flex gap-4 items-center w-full border-b-[0.5px] border-b-[#D0D5DD] pb-4'>
                            <div className="border-[#EEF3FF] bg-[#1851e430] rounded-[39px] border-8">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.33325H6C5.632 9.33325 5.33334 9.03458 5.33334 8.66658C5.33334 8.29858 5.632 7.99992 6 7.99992H8C8.368 7.99992 8.66667 8.29858 8.66667 8.66658C8.66667 9.03458 8.368 9.33325 8 9.33325ZM5.33334 11.3333C5.33334 10.9653 5.632 10.6666 6 10.6666H10C10.3687 10.6666 10.6667 10.9653 10.6667 11.3333C10.6667 11.7013 10.3687 11.9999 10 11.9999H6C5.632 11.9999 5.33334 11.7013 5.33334 11.3333ZM11.6295 13.3333H4.3702C4.1662 13.3333 4.0002 13.1839 4.0002 12.9999V2.99992C4.0002 2.81592 4.1662 2.66659 4.3702 2.66659H8.0002V4.76659C8.0002 5.81459 8.81154 6.66658 9.80954 6.66658H12.0002V12.9999C12.0002 13.1839 11.8342 13.3333 11.6295 13.3333ZM9.33354 3.31859L11.1615 5.33325H9.80954C9.54687 5.33325 9.33354 5.07925 9.33354 4.76659V3.31859ZM13.1602 5.55192L9.53087 1.55192C9.4042 1.41259 9.22554 1.33325 9.03687 1.33325H4.3702C3.43087 1.33325 2.66687 2.08125 2.66687 2.99992V12.9999C2.66687 13.9186 3.43087 14.6666 4.3702 14.6666H11.6295C12.5689 14.6666 13.3335 13.9186 13.3335 12.9999V5.99992C13.3335 5.83392 13.2715 5.67459 13.1602 5.55192Z" fill="#1851E4" />
                                </svg>
                            </div>
                            <p className='text-lg text-primary-400 font-medium'>Your Invoice</p>
                        </div>
                        {orderDetails ? <>
                            <div className="flex items-center gap-2 my-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z" fill="#98A2B3" />
                                </svg>
                                <p className="text-primary-100 text-base">Diagnostics fee will be eliminated if you are fixing in our base</p>
                            </div>

                            {/* Invoice info */}
                            <div className="rounded-lg">
                                {/* Column header */}

                                <div className="grid grid-cols-2 w-full bg-[#F4F8FD] border-b border-b-[#EAECF0] px-6 py-3 rounded-t-lg">
                                    <p className="text-primary-100 text-base">
                                        Description
                                    </p>
                                    <p className="text-primary-100 text-base text-right">
                                        Amount
                                    </p>
                                </div>

                                {/* Invoices */}
                                {diagnosisInvoices.map((invoice, index) => (<div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-6 py-4 border-b border-b-[#EAECF0]">
                                    <p className="text-primary-400 text-sm font-medium">
                                        {invoice.title}
                                    </p>
                                    <p className="text-primary-400 text-sm text-right font-medium">
                                        N{Number(invoice.amount).toLocaleString()}
                                    </p>
                                </div>))}

                                {/* Total cost */}
                                <div className="grid grid-cols-2 w-full bg-[#F9FAFB] px-7 py-4 rounded-b-lg">
                                    <p className="text-primary-400 text-base font-semibold">
                                        Total
                                    </p>
                                    <p className="text-primary-400 text-base text-right font-semibold">
                                        N{totalAmount.toLocaleString()}
                                    </p>
                                </div>

                                <div className="w-full flex justify-end mt-[37px] gap-3">
                                    <button className="rounded-3xl bg-[#F9F5FF] py-2 px-[14px]" onClick={closeCoupleBackModal}>
                                        <div className="flex items-center justify-center text-sm font-semibold text-primary-300">
                                            Go back
                                        </div>
                                    </button>
                                    <button disabled={orderDetails.order?.isPaid} className="rounded-3xl bg-primary-300 py-2 px-[14px]" onClick={handlePaymentOptionsModal}>
                                        <div className="flex items-center justify-center text-sm font-semibold text-white">
                                            Proceed to payment
                                        </div>
                                    </button>
                                </div>

                            </div>
                        </> : <div className="mt-6 text-primary-100 text-lg">
                            No invoice has been added
                        </div>}
                    </div>
                </Modal>

                <div className="flex items-center w-full lg:hidden mb-8">
                    <ArrowLeft size="24" color="#1F1844" onClick={goBack} />
                    <div className='w-full flex justify-center'>
                        <p className="text-lg text-primary-300 font-medium">Repair details</p>
                    </div>
                </div>

                {pageLoadingProgress ? <div className="flex w-full h-full items-center justify-center">
                    <BeatLoader color='#1F1844' size={30} />
                </div> : <>
                    <div className="flex w-full gap-6">
                        {/* Device info */}
                        <div className='flex flex-col w-full md:w-0 md:min-w-max border-[#EAECF0] rounded-lg border p-6' >
                            <div className='flex items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4  w-full gap-8'>
                                <p className='text-lg text-primary-400 font-medium'>Repair ID #{orderDetails.order.displayName}</p>
                                {getStatusChip(orderDetails.order)}
                            </div>
                            <div className="grid grid-cols-2 mt-6 text-primary-100 text-sm">
                                <div className="flex flex-col gap-1 mb-[25px]">
                                    <p className='font-semibold'>Device type</p>
                                    <p>{orderDetails.order?.device?.type}</p>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className='font-semibold'>Device Brand</p>
                                    <p>{orderDetails.order?.device?.brand}</p>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className='font-semibold'>Device Model</p>
                                    <p>{orderDetails.order?.device?.model}</p>
                                </div>
                            </div>
                        </div>
                        {/* Device issue */}
                        <div className='md:flex hidden flex-col border-[#EAECF0] rounded-lg border p-6 w-full' >
                            <div className='flex gap-4 items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4  w-full'>
                                <div className="border-[#FFFAEB] bg-[#FEF0C7] rounded-[39px] border-8">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M7.99986 5.33339V8.00006M7.99986 10.6667H8.00653M6.85986 1.90672L1.21319 11.3334C1.09677 11.535 1.03517 11.7636 1.03452 11.9964C1.03387 12.2292 1.09419 12.4581 1.20948 12.6604C1.32477 12.8627 1.49101 13.0312 1.69167 13.1493C1.89232 13.2674 2.12039 13.3308 2.35319 13.3334H13.6465C13.8793 13.3308 14.1074 13.2674 14.3081 13.1493C14.5087 13.0312 14.675 12.8627 14.7902 12.6604C14.9055 12.4581 14.9659 12.2292 14.9652 11.9964C14.9646 11.7636 14.903 11.535 14.7865 11.3334L9.13986 1.90672C9.02101 1.71079 8.85368 1.5488 8.65399 1.43638C8.45431 1.32395 8.22902 1.26489 7.99986 1.26489C7.7707 1.26489 7.54542 1.32395 7.34573 1.43638C7.14605 1.5488 6.97871 1.71079 6.85986 1.90672Z" stroke="#DC6803" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p className='text-lg text-primary-400 font-medium'>Device Issue</p>
                            </div>
                            <div className="mt-6 text-primary-100 text-lg">
                                {orderDetails.order?.device?.complaint}
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-col md:flex-row w-full gap-4 mt-6">
                        {/* Device issues */}
                        <div className='flex flex-col w-full border-[#EAECF0] rounded-lg border p-6 md:hidden' >
                            <div className="flex justify-between items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4">
                                <div className='flex gap-4 items-center w-full'>
                                    <div className="border-[#FFFAEB] bg-[#FEF0C7] rounded-[39px] border-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M7.99986 5.33339V8.00006M7.99986 10.6667H8.00653M6.85986 1.90672L1.21319 11.3334C1.09677 11.535 1.03517 11.7636 1.03452 11.9964C1.03387 12.2292 1.09419 12.4581 1.20948 12.6604C1.32477 12.8627 1.49101 13.0312 1.69167 13.1493C1.89232 13.2674 2.12039 13.3308 2.35319 13.3334H13.6465C13.8793 13.3308 14.1074 13.2674 14.3081 13.1493C14.5087 13.0312 14.675 12.8627 14.7902 12.6604C14.9055 12.4581 14.9659 12.2292 14.9652 11.9964C14.9646 11.7636 14.903 11.535 14.7865 11.3334L9.13986 1.90672C9.02101 1.71079 8.85368 1.5488 8.65399 1.43638C8.45431 1.32395 8.22902 1.26489 7.99986 1.26489C7.7707 1.26489 7.54542 1.32395 7.34573 1.43638C7.14605 1.5488 6.97871 1.71079 6.85986 1.90672Z" stroke="#DC6803" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <p className='text-lg text-primary-400 font-medium'>Device Issue</p>
                                </div>
                                <div className="flex justify-end">
                                    <button className='flex bg-transparent gap-2 items-center w-full' onClick={handlOpenDeviceIssueModal}>
                                        <p className='text-lg text-primary-100'>View</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9995 19.0001C9.7735 19.0001 9.5465 18.9241 9.3595 18.7681C8.9355 18.4151 8.8785 17.7841 9.2315 17.3601L13.7075 11.9891L9.3925 6.62707C9.0465 6.19707 9.1145 5.56707 9.5445 5.22107C9.9755 4.87507 10.6045 4.94307 10.9515 5.37307L15.7795 11.3731C16.0775 11.7441 16.0735 12.2741 15.7685 12.6401L10.7685 18.6401C10.5705 18.8771 10.2865 19.0001 9.9995 19.0001Z" fill="#1F1844" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Diagnosis */}
                        <div className='flex flex-col w-full border-[#EAECF0] rounded-lg border p-6' >
                            <div className="flex justify-between items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4">
                                <div className='flex gap-4 items-center w-full'>
                                    <div className="border-[#FEF3F2] bg-[#FEE4E2] rounded-[39px] border-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_556_9684)">
                                                <path d="M8 5.33325V7.99992M8 10.6666H8.00667M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8 14.6666C4.3181 14.6666 1.33333 11.6818 1.33333 7.99992C1.33333 4.31802 4.3181 1.33325 8 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_556_9684">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p className='text-lg text-primary-400 font-medium'>Diagnosis</p>
                                </div>
                                <div className="flex justify-end">
                                    <button className='flex bg-transparent gap-2 items-center w-full' onClick={handlOpenDiagnosisModal}>
                                        <p className='text-lg text-primary-100'>View</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9995 19.0001C9.7735 19.0001 9.5465 18.9241 9.3595 18.7681C8.9355 18.4151 8.8785 17.7841 9.2315 17.3601L13.7075 11.9891L9.3925 6.62707C9.0465 6.19707 9.1145 5.56707 9.5445 5.22107C9.9755 4.87507 10.6045 4.94307 10.9515 5.37307L15.7795 11.3731C16.0775 11.7441 16.0735 12.2741 15.7685 12.6401L10.7685 18.6401C10.5705 18.8771 10.2865 19.0001 9.9995 19.0001Z" fill="#1F1844" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6 text-primary-100 text-lg hidden md:block">
                                {orderDetails.diagnosis ? 'Diagnosis is available' : 'You will be notified when your device diagnostics report is ready.'}
                            </div>
                        </div>

                        {/* Invoice */}
                        <div className='flex flex-col w-full border-[#EAECF0] rounded-lg border p-6' >
                            <div className="flex justify-between items-center border-b-[0.5px] border-b-[#D0D5DD] pb-4">
                                <div className='flex gap-4 items-center w-full'>
                                    <div className="border-[#EEF3FF] bg-[#1851e430] rounded-[39px] border-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.33325H6C5.632 9.33325 5.33334 9.03458 5.33334 8.66658C5.33334 8.29858 5.632 7.99992 6 7.99992H8C8.368 7.99992 8.66667 8.29858 8.66667 8.66658C8.66667 9.03458 8.368 9.33325 8 9.33325ZM5.33334 11.3333C5.33334 10.9653 5.632 10.6666 6 10.6666H10C10.3687 10.6666 10.6667 10.9653 10.6667 11.3333C10.6667 11.7013 10.3687 11.9999 10 11.9999H6C5.632 11.9999 5.33334 11.7013 5.33334 11.3333ZM11.6295 13.3333H4.3702C4.1662 13.3333 4.0002 13.1839 4.0002 12.9999V2.99992C4.0002 2.81592 4.1662 2.66659 4.3702 2.66659H8.0002V4.76659C8.0002 5.81459 8.81154 6.66658 9.80954 6.66658H12.0002V12.9999C12.0002 13.1839 11.8342 13.3333 11.6295 13.3333ZM9.33354 3.31859L11.1615 5.33325H9.80954C9.54687 5.33325 9.33354 5.07925 9.33354 4.76659V3.31859ZM13.1602 5.55192L9.53087 1.55192C9.4042 1.41259 9.22554 1.33325 9.03687 1.33325H4.3702C3.43087 1.33325 2.66687 2.08125 2.66687 2.99992V12.9999C2.66687 13.9186 3.43087 14.6666 4.3702 14.6666H11.6295C12.5689 14.6666 13.3335 13.9186 13.3335 12.9999V5.99992C13.3335 5.83392 13.2715 5.67459 13.1602 5.55192Z" fill="#1851E4" />
                                        </svg>
                                    </div>
                                    <p className='text-lg text-primary-400 font-medium'>Your Invoice</p>
                                </div>
                                <div className="flex justify-end">
                                    <button className='flex bg-transparent gap-2 items-center w-full group' onClick={handleOpenInvoiceModal}>
                                        <p className='text-lg text-primary-100 group-hover:text-[F04301]'>View</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9995 19.0001C9.7735 19.0001 9.5465 18.9241 9.3595 18.7681C8.9355 18.4151 8.8785 17.7841 9.2315 17.3601L13.7075 11.9891L9.3925 6.62707C9.0465 6.19707 9.1145 5.56707 9.5445 5.22107C9.9755 4.87507 10.6045 4.94307 10.9515 5.37307L15.7795 11.3731C16.0775 11.7441 16.0735 12.2741 15.7685 12.6401L10.7685 18.6401C10.5705 18.8771 10.2865 19.0001 9.9995 19.0001Z" fill="#1F1844" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6 text-primary-100 text-lg hidden md:block">
                                {orderDetails.invoices.length < 1 ? 'You will be notified when your invoice is ready.' : "Invoice is available"}
                            </div>
                        </div>
                    </div>
                </>}
            </>
        </Layout>
    )
}

export default OrderDetailsPage
