import { BeatLoader } from "react-spinners";
import EmptyRepair from "./pages/EmptyRepair";
import { useRef, useState } from "react";
import { Image, Modal, Progress, Table, message } from "antd";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import axios from "axios";
import FormInput from "./FormInput";
import Button from "./Button";
import ConfirmationModal from "./ConfirmationModal";
import { useHistory } from "react-router";

export default function RepairTable({ tableData, tableLoadingProgress, user }) {
    const history = useHistory()
    //Order parameters
    const [selectedDeviceType, setSelectedDeviceType] = useState('');
    const [selectedDeviceBrand, setSelectedDeviceBrand] = useState('');
    const [deviceModel, setDeviceModel] = useState('');
    const [address, setAddress] = useState('');
    const [complaint, setComplaint] = useState('');
    const [image, setImage] = useState();
    const [imageSrc, setImageSrc] = useState('');
    const [order, setOrder] = useState();

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const inputRef = useRef(null)

    const deviceTypes = [
        { value: 'Laptop', label: 'Laptop' },
        { value: 'Mobile Phone', label: 'Mobile Phone' },
        { value: 'Other', label: 'Other' },
    ];

    const deviceBrands = [
        { value: 'Samsung', label: 'Samsung' },
        { value: 'Apple', label: 'Apple' },
        { value: 'Tecno', label: 'Tecno' },
        { value: 'Itel', label: 'Itel' },
        { value: 'Infinix', label: 'Infinix' },
        { value: 'Oppo', label: 'Oppo' },
        { value: 'Redmi', label: 'Redmi' },
        { value: 'Htc', label: 'Htc' },
        { value: 'Sony', label: 'Sony' },
    ];

    function handleOnUploadImage() {
        inputRef && inputRef.current.click();
    }

    function handleViewRepairDetails() {
        history.push(`/request/detials/${order?.id}`)
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = function (e) {
            setImageSrc(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    const onSubmitOrder = async () => {
        setIsButtonLoading(true)
        const formData = new FormData();
        formData.append('Model', deviceModel)
        formData.append('Brand', selectedDeviceBrand.value)
        formData.append('Type', selectedDeviceType.value)
        formData.append('Address', address)
        formData.append('Complaint', complaint)
        formData.append('Image', image)
        formData.append('UserId', user.id)

        try {
            const result = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/Order`, formData)
            setOrder(result.data.data)
            hideModal()
            showConfirmationModal()
        } catch (error) {
            getGenerateErrorResponse(error)
        }
        setIsButtonLoading(false)
    }

    function getGenerateErrorResponse(error) {
        if (error.response) {
            message.error(error.response.data.info)
        } else {
            message.error('Oops! Something went wrong. Please try again later')
        }
        setIsButtonLoading(false)
    }

    function showConfirmationModal() {
        setOpenConfirmationModal(true)
    }
    function hideConfirmationModal() {
        setOpenConfirmationModal(false)
    }

    const [open, setOpen] = useState(false)
    function showModal() {
        setOpen(true)
    }
    function hideModal() {
        setOpen(false)
    }

    function getStatusChip(record) {
        switch (record.orderStatusId) {
            case 1:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#EEF3FF] rounded-2xl text-[#114FEE] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#FFEDED] rounded-2xl text-[#E43B3B] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="flex w-full justify-start">
                        <div className='bg-[#ECFDF3] rounded-2xl text-[#027A48] text-xs text-center px-2 py-1'>
                            {record.status}
                        </div>
                    </div>
                );
        }
    }

    function getStatusProgress(orderStatusId) {
        switch (Number(orderStatusId)) {
            case 1:
                return (
                    <Progress percent={20} strokeColor={'#F04301'} />
                );
            case 2:
                return (
                    <Progress percent={60} strokeColor={'#F04301'} />
                );
            case 4:
                return (
                    <Progress percent={100} strokeColor={'#F04301'} />
                );
            case 5:
                return (
                    <Progress percent={100} strokeColor={'#32D583'} />
                );

        }
    }

    const columns = [
        {
            title: 'Repair ID',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (date) => format(new Date(date), "MMM dd, yyyy")
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <div className='flex flex-col'>
                    {getStatusChip(record)}
                    {getStatusProgress(record.orderStatusId)}
                </div>
            ),
        },
        {
            title: 'Action',
            render: (_, record) => (
                <Link to={`/order/details/${record.id}`}>
                    <p className='text-[#F04301] text-sm font-semibold'>View Details</p>
                </Link>
            ),
        },
    ]

    return (
        <>
            {/* New repair modal */}
            <Modal
                open={open}
                onCancel={hideModal}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <div className="flex-flex-col w-full">
                    <div className="w-full justify-center text-center border-b-[#D0D5DD] border-b pb-8 mb-8">
                        <p className="text-lg text-primary-300 font-medium">Repair your device</p>
                        <p className="text-lg text-primary-100">Please provide your device details and specify the problem you are experiencing with your device.</p>
                    </div>
                    <div className="flex flex-col gap-4">
                        <FormInput type='select' label='Device type' key={0} options={deviceTypes} value={selectedDeviceType} setValue={setSelectedDeviceType} />
                        <FormInput type='select' label='Device brand' key={1} options={deviceBrands} value={selectedDeviceBrand} setValue={setSelectedDeviceBrand} />
                        <FormInput type='text' placeholder='eg. iphone 11pro' label='Device model' key={2} setValue={setDeviceModel} value={deviceModel} />
                        <FormInput type='textarea' label='Address' placeholder='Enter address' key={3} value={address} setValue={setAddress} />
                        <FormInput type='textarea' placeholder='describe issues here' label='Issue description' key={4} value={complaint} setValue={setComplaint} />
                        <div className="flex flex-col gap-[6px] mb-4">
                            <p className="text-primary-400 font-medium">Upload image</p>
                            <div className="border border-[#114FEE] border-dashed rounded-[10px] py-6" onClick={handleOnUploadImage}>
                                {!imageSrc ? <div className="flex flex-col gap-2 w-full cursor-pointer justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5002 3C12.2992 3 12.0982 3.061 11.9252 3.182L7.92521 5.996C7.47321 6.314 7.36421 6.938 7.68221 7.389C8.00021 7.841 8.62321 7.95 9.07521 7.632L11.5028 5.92426C11.501 5.94926 11.5 5.97451 11.5 5.99999V14C11.5 14.552 11.947 15 12.5 15C13.053 15 13.5 14.552 13.5 14V6.00002L15.9002 7.8C16.3422 8.133 16.9692 8.042 17.3002 7.6C17.6322 7.158 17.5422 6.531 17.1002 6.2L13.1002 3.2C12.9232 3.067 12.7112 3 12.5002 3ZM6.5 17V18H18.5V17C18.5 16.45 18.95 16 19.5 16C20.05 16 20.5 16.45 20.5 17V19C20.5 19.55 20.05 20 19.5 20H5.5C4.95 20 4.5 19.55 4.5 19V17C4.5 16.45 4.95 16 5.5 16C6.05 16 6.5 16.45 6.5 17Z" fill="#667085" />
                                    </svg>
                                    <p className="text-base font-medium text-[#114FEE]">Choose file to upload</p>
                                    <p className="text-base font-medium text-primary-100">file supported are jpeg, png</p>
                                </div> : <div className='w-full flex items-center justify-center'>
                                    <Image
                                        width={200}
                                        src={imageSrc}
                                    />
                                </div>}
                            </div>
                            <input type="file" ref={inputRef} className='hidden' name="myImage" onChange={handleImageChange} accept="image/png, image/jpeg" on />
                        </div>

                        <Button onButtonClick={onSubmitOrder} disabled={!address || !selectedDeviceBrand || !selectedDeviceType || !complaint || !deviceModel || isButtonLoading}>
                            Submit repair request
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* Confirmation modal */}
            <ConfirmationModal open={openConfirmationModal}
                secondaryButtonText='View order details' primaryButtonText='Done'
                mainTitle='Your order has been submitted successfully'
                subTitle='You will receive an email confirming your order'
                hideModal={hideConfirmationModal}
                primaryButtonClick={hideConfirmationModal}
                secondaryButtonClick={handleViewRepairDetails}
            />

            <div className='flex flex-col w-full rounded-lg border border-[#EAECF0]'>
                <div className="flex w-full border-b border-[#EAECF0] justify-between px-6 py-5 items-center">
                    <p className="text-lg text-primary300 font-medium justify-start">Repair Orders</p>

                    <div className="md:flex gap-3 hidden">
                        <button className='bg-primary-300 rounded-[30px] text-white text-sm font-semibold p-3 rounded-[20px]' onClick={showModal}>
                            Repair device
                        </button>
                    </div>
                </div>
                <>

                </>
                {/* No repair screen */}

                {tableLoadingProgress ? <div className="flex w-full items-center justify-center py-10">
                    <BeatLoader color='#1F1844' size={20} />
                </div> :
                    <>
                        {tableData.length < 1
                            ?
                            <EmptyRepair onButtonClick={showModal} />
                            :
                            <Table dataSource={tableData} columns={columns} />}
                    </>}
            </div>
        </>
    )
}