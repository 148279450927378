import React from 'react';
import { Route, Redirect } from 'react-router-dom';  
import { useState } from 'react';


function ProtectedRoutes({isAuth: isAuth, user: user, isFirstTime: isFirstTime, component: Component, ...rest}) {

    const [toggleLeftBar, setToggleLeftBar] = useState(false);

    return <Route {...rest} render={(props) => {
        if (isAuth) {
            return <Component user={user} toggleLeftBar={toggleLeftBar} setToggleLeftBar={setToggleLeftBar}/>
        } else if (!isAuth && isFirstTime) {
            return (<Redirect to={{pathname: "/onboarding", state: { from: props.location} }}/>);
        } else {
            return (<Redirect to={{pathname: "/login", state: { from: props.location} }}/>);
        }
    }}
    />
}

export default ProtectedRoutes
