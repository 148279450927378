import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import logoLarge from "../../../assets/img/tb-logo-xd.png";
import { css } from "@emotion/react";
import { BeatLoader, ClipLoader } from "react-spinners";
import axios from 'axios';
import { useHistory } from 'react-router';
import './styles.css';
import { Link } from 'react-router-dom';
import FormInput from '../../FormInput';
import Button from '../../Button';
import { message } from 'antd';
import axiosInstance from '../../../helpers/axios';


const useStyles = makeStyles((theme) => ({

    logoGrp: {
        marginBottom: "30px",
    },

    logoLarge: {

    },
    signUpBackground: {
        margin: "auto",
        width: "70%",
        marginTop: "80px",
        padding: "20px",
        textAlign: "center"
    },
    logoLg: {
        display: "block",
        maxWidth: "45%",
        height: "auto",
    },

    form: {
        padding: theme.spacing(2),
    },

    item: {
        marginBottom: theme.spacing(4),
    },

    label: {
        color: "#D3D3D3",
    },

    borderbottom: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E56F09"
        },
    },
    textfield: {
        color: "#D3D3D3",
    },

    privacyText: {
        paddingLeft: 25,
        paddingRight: 25,
        display: "flex",
        textAlign: "center",
        marginBottom: 25,
    },
    button: {
        backgroundColor: "#E56F09",
        borderRadius: "20px",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 18,
        fontWeight: "700",
        color: "#1C1045",
        border: "none",
        cursor: "pointer",
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: "0.4",
        },
    },

    sideItem: {
        display: "flex",
        flexDirection: "row",
        "flex-wrap": "wrap",
        position: "absolute",
        bottom: 60,
        right: 10,
        backgroundColor: "#fff",
        borderRadius: "20px",
        padding: 10,
        width: "260px",
        "min-width": "260px",
        paddingLeft: "15px",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    sideItems: {
        fontSIze: "15px",
    },
}));
const Login = () => {

    const classes = useStyles();
    const baseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/Auth`;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const handleLogin = async () => {
        if (!loadingProgress) {
            setLoadingProgress(true);
        }

        try {
            const res = await axiosInstance.post(`/Auth/signin`, {
                email,
                password
            });
            debugger;
            console.log(res.data)

            if (res.status === 200) {
                localStorage.setItem('user', JSON.stringify(res.data.user));
                localStorage.setItem('isFirstTime', 'false');
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 2000);
            } else {
                message.error(res.data.info)
            }
            setLoadingProgress(false);
        } catch (error) {

        }
    }

    return (
        <>
            {/* Web view */}
            <div className='bg-[#F5F5F5] h-full font-inter py-14 px-16 hidden md:flex flex-col'>
                <img src='logo.png' className='w-[241px] h-[63px]' />

                <div className='flex w-full justify-center p-4'>
                    <div className='bg-white rounded-lg p-8'>
                        <div className='flex flex-col gap-4 mb-6 w-full justify-center items-center'>
                            <p className='text-2xl text-primary-300 font-medium'>Welcome back</p>
                            <p className='text-primary-100 text-lg'>Don’t have an account? <Link to='/signup'><span className='text-primary-200'>Create an account.</span></Link></p>
                        </div>

                        <div className="flex flex-col gap-4 mb-">
                            <FormInput label='Email' type='text' setValue={setEmail} value={email} />
                            <FormInput label='Password' type='password' setValue={setPassword} value={password} />
                            <div className='w-full mt-4'>
                                <Button onButtonClick={handleLogin} disabled={!email || !password || loadingProgress}>
                                    {loadingProgress ? <div className='w-full justify-center h-full items-center'>
                                        <ClipLoader color='#fff' size={20} />
                                    </div> : 'Continue'}
                                </Button>
                            </div>
                        </div>

                        <div className='flex w-full justify-center gap-6 items-center my-[43px]'>
                            <img src='line.svg' alt='line' />
                            <p className='text-primary-100 text-2xl'>or</p>
                            <img src='line.svg' alt='line' />
                        </div>

                        <div className="flex flex-col gap-4">
                            <Button isSecondary={true}>
                                <div className='flex items-center gap-3 w-full justify-center'>
                                    <img src='google.svg' alt='google' />
                                    <p className='text-primary-400 group-hover:text-white text-base font-semibold'>Sign in with Google</p>
                                </div>
                            </Button>
                            <Button isSecondary={true}>
                                <div className='flex items-center gap-3 w-full justify-center'>
                                    <img src='facebook.svg' alt='google' />
                                    <p className='text-primary-400 group-hover:text-white text-base font-semibold'>Sign in with Facebook</p>
                                </div>
                            </Button>
                        </div>

                        <div className='w-full justify-center mt-8 text-center items-center'>
                            <Link to='#'>
                                <p className='text-primary-100 text-lg'>Forgot password?</p>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>

            <div className="bg-primary-300 font-inter md:hidden h-screen w-full pt-11 px-6 pb-[118px]">
                <img src='/logo-white.svg' alt='logo' />

                <div className="flex flex-col justify-center w-full text-center mt-[61px] mb-[85px]">
                    <p className='text-white text-2xl font-medium font-poppins'>Log in to request a repair</p>
                    <p className='font-dmsans text-white text-base'>Don’t have an account? <Link to='/signup'><span className="font-bold text-[#F04301]">Create an account</span></Link>  </p>
                </div>

                {/* Login form */}
                <div className="flex flex-col gap-4 border-b border-b-[#e9e9005c]">
                    <FormInput label='Email' type='text' isDarkMode={true} setValue={setEmail} value={email} />
                    <FormInput label='Password' type='password' isDarkMode={true} setValue={setPassword} value={password} />
                    <div className='w-full mt-4'>
                        <button className="hover:bg-primary-300 hover:text-white group bg-[#F04301] hover:border-primary-300 border-[#D0D5DD] text-white text-base font-semibold p-3 w-full rounded-[30px]" onClick={handleLogin}
                            disabled={!email || !password || loadingProgress}>
                            {loadingProgress ? <div className='w-full justify-center h-full items-center'>
                                <ClipLoader color='#fff' size={20} />
                            </div> : 'Continue with email'}
                        </button>
                    </div>
                    <Link to='#'><p className="text-base font-dmsans text-center underline text-white mt-2 mb-6">Forgot password?</p></Link>
                </div>

                <div className='w-full mt-6'>
                    <button className="bg-transparent border-white border text-white text-base font-semibold p-3 w-full rounded" 
                        disabled={!email || !password || loadingProgress}>
                        <div className='flex items-center gap-3 w-full justify-center'>
                            <img src='google.svg' alt='google' />
                            <p className='text-primary-400 group-hover:text-white text-base font-semibold'>Sign in with Google</p>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Login
