import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { BeatLoader } from "react-spinners";
import { useHistory } from 'react-router';
import './styles.css';
import Leftbar from '../../Leftbar';
import RightbarNav from '../../RightBarNav';
import axiosInstance from '../../../helpers/axios';
import { Tabs, Tab } from '@material-ui/core';
import { Person, Business } from '@material-ui/icons';
import RepairTable from '../../RepairTable';
import Layout from '../../Layout';


const Request = ({ user, toggleLeftBar, setToggleLeftBar }) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log('New value ' + newValue)
        setValue(newValue);
    };
    // let rows = [];
    // for (let i = 0; i < 500; i+=10) {
    //     rows.push(<option value={(i + 1) + '-' + (i + 10)}>{(i + 1) + '-' + (i + 10)}</option>);
    //     }

    const [tableData, setTableData] = useState([]);

    const [loadingProgress, setLoadingProgress] = useState(true);
    const [responseMessage, setResponseMessage] = useState("");
    const history = useHistory();
    const [showMobileNav, setShowMobileNav] = useState(false)

    useEffect(() => {
        const fetchUserOrders = async () => {
            try {
                const res = await axiosInstance.get(`/Order/Customer/${user.id}`);
                if (res.data.success === true) {
                    setTableData(res.data.data)
                }
            } catch (error) {

            }
            setLoadingProgress(false);
        }
        fetchUserOrders();
    }, []);

    useEffect(() => {
        setToggleLeftBar(false);
    }, [])

    return (
        <Layout pageTitle={'Repair'} showMobileNav={showMobileNav} user={user}>
            <div className="flex justify-between items-start lg:hidden mb-10">
                <p className="text-2xl md:text-3xl text-primary-300 font-semibold">Repair Orders</p>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowMobileNav(!showMobileNav)} className='md:hidden cursor-point' width="38" height="38" viewBox="0 0 40 40" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58049 10H33.4188C34.2888 10 35.0005 10.7117 35.0005 11.5817V11.7517C35.0005 12.6217 34.2888 13.3333 33.4188 13.3333H6.58049C5.71049 13.3333 5.00049 12.6217 5.00049 11.7517V11.5817C5.00049 10.7117 5.71049 10 6.58049 10ZM33.4188 18.3333H6.58049C5.71049 18.3333 5.00049 19.045 5.00049 19.915V20.085C5.00049 20.955 5.71049 21.6667 6.58049 21.6667H33.4188C34.2888 21.6667 35.0005 20.955 35.0005 20.085V19.915C35.0005 19.045 34.2888 18.3333 33.4188 18.3333ZM33.4188 26.6667H6.58049C5.71049 26.6667 5.00049 27.3783 5.00049 28.2483V28.4183C5.00049 29.2883 5.71049 30 6.58049 30H33.4188C34.2888 30 35.0005 29.2883 35.0005 28.4183V28.2483C35.0005 27.3783 34.2888 26.6667 33.4188 26.6667Z" fill="#121212" />
                </svg>
            </div>

            <RepairTable tableLoadingProgress={loadingProgress} user={user} tableData={tableData} />
        </Layout>
    )
}

export default Request
