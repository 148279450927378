import React from 'react';
import { GoogleMap, LoadScript, useJsApiLoader, Marker  } from '@react-google-maps/api';

const containerStyle = {
    width: '99%',
    height: '800px'
};
  
const center = {
    lat: -3.745,
    lng: -38.523
};

const GoogleMapComponent = () => {
    return (
        <LoadScript
        googleMapsApiKey="AIzaSyB2CqUaUSTk1tsLcfQJtn0WlBLPorYhPNU"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={12}
                center={{ lat:6.5968779888521105, lng: 3.3575377972691705}}
            >
                <Marker position={{ lat:6.5968779888521105, lng: 3.3575377972691705}} />
            </GoogleMap>
        </LoadScript>
    )
}


export default GoogleMapComponent
