import { Checkbox, FormControlLabel, TextField, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import logoLarge from "../../../assets/img/tb-logo-xd.png";
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { useHistory } from 'react-router';
import './styles.css';
import { css } from "@emotion/react";
import { BeatLoader, ClipLoader } from "react-spinners";
import { Link } from 'react-router-dom';
import FormInput from '../../FormInput';
import Button from '../../Button';
import axiosInstance from '../../../helpers/axios';
import { message } from 'antd';


const SignUp = () => {

  const baseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/Auth`;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [isBusinessAccount, setIsBusinessAccount] = useState(false);



  const history = useHistory();

  function onChangeAccountType() {
    setIsBusinessAccount(!isBusinessAccount)
  }

  function handleCheckedPolicy(e, isChecked) {
    setCheckedPolicy(e.target.checked);

  }
  const handleSignUp = async () => {
    // e.preventDefault();
    if (!loadingProgress) {
      setLoadingProgress(true);
    }

    try {
      const res = await axiosInstance.post(`${baseUrl}/SignUp`, {
        firstName,
        lastName,
        email: email,
        phoneNumber: phoneNumber,
        userTypeId: 3,
        picture: "string",
        accountType: isBusinessAccount ? 1 : 0,
        password,
        wallet: 0,
        address: address,
      });

      if (res.status === 200) {
        message.success('An OTP code has been sent to your email, you will be redirected')
        localStorage.setItem('email', email);
        setTimeout(() => {
          history.push("/otpverification")
        }, 2000);
      }
    } catch (error) {
      setLoadingProgress(false);
      message.error('Something went wrong please try again later')
    }
  }


  return (
    <div className='md:bg-[#F5F5F5] h-full font-inter'>
      <div className='flex w-full justify-center md:p-4 lg:p-8'>
        <div className='bg-white rounded-lg p-6 md:p-8'>
          {/* Web view */}
          <div className='md:flex hidden flex-col gap-4 mb-6 w-full justify-center items-center'>
            <p className='text-2xl text-primary-300 font-medium'>Welcome back</p>
            <p className='text-primary-100 text-lg'>Select account type</p>
          </div>
          <div className='flex flex-col gap-1 mb-6 w-full justify-center items-center md:hidden'>
            <p className='text-2xl text-primary-300 font-medium'>Create an account</p>
            <p className='text-primary-100 text-base'>Select account type</p>
            <p className='text-primary-100 text-base mt-3'>Already have an account? <Link to='/login'><span className="font-semibold text-[#F04301]">Sign in</span></Link> </p>
          </div>

          {/* Account type button */}
          <div className='flex w-full gap-4 mb-8'>
            {/* Individual account button active and inactive state */}
            {!isBusinessAccount ? <div className='flex items-start w-full'>
              <img src='check.svg' alt='check' className='absolute mt-[-10px] ml-[-10px]' />
              <button onClick={onChangeAccountType} className='bg-primary-300 border-2 border-primary-200 w-full py-4 px-[18px] rounded-[15px] justify-center items-center'>
                <div className='flex pl-3 items-center gap-4 relative'>
                  <svg className='w-[18px] lg:w-8 h-[18px] lg:h-8' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="vuesax/bold/user">
                      <g id="user">
                        <path id="Vector" d="M15.9999 16.0003C19.6818 16.0003 22.6666 13.0156 22.6666 9.33366C22.6666 5.65176 19.6818 2.66699 15.9999 2.66699C12.318 2.66699 9.33325 5.65176 9.33325 9.33366C9.33325 13.0156 12.318 16.0003 15.9999 16.0003Z" fill="#FFFF" />
                        <path id="Vector_2" d="M15.9999 19.333C9.31988 19.333 3.87988 23.813 3.87988 29.333C3.87988 29.7063 4.17322 29.9997 4.54655 29.9997H27.4532C27.8266 29.9997 28.1199 29.7063 28.1199 29.333C28.1199 23.813 22.6799 19.333 15.9999 19.333Z" fill="#FFFF" />
                      </g>
                    </g>
                  </svg>
                  <div className='flex h-full w-full items-center pr-6'>
                    <p className='text-primary-300 text-white font-medium mb-[-3px] text-base lg:text-[22px]'>Individual</p>
                  </div>
                  <img src='eclispe.svg' alt='check' className='absolute left-full opacity-15 ml-[-20px]' />

                </div>
              </button>
            </div> :
              <button onClick={onChangeAccountType} className='bg-[#EAECF0] w-full rounded-[15px] py-4 px-[18px] justify-center items-center'>
                <div className='flex w-full items-center text-white gap-4'>
                  <svg className='w-[18px] lg:w-8 h-[18px] lg:h-8' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="vuesax/bold/user">
                      <g id="user">
                        <path id="Vector" d="M15.9999 16.0003C19.6818 16.0003 22.6666 13.0156 22.6666 9.33366C22.6666 5.65176 19.6818 2.66699 15.9999 2.66699C12.318 2.66699 9.33325 5.65176 9.33325 9.33366C9.33325 13.0156 12.318 16.0003 15.9999 16.0003Z" fill="#1F1844" />
                        <path id="Vector_2" d="M15.9999 19.333C9.31988 19.333 3.87988 23.813 3.87988 29.333C3.87988 29.7063 4.17322 29.9997 4.54655 29.9997H27.4532C27.8266 29.9997 28.1199 29.7063 28.1199 29.333C28.1199 23.813 22.6799 19.333 15.9999 19.333Z" fill="#1F1844" />
                      </g>
                    </g>
                  </svg>

                  <p className='text-primary-300 font-medium text-base lg:text-[22px] mb-[-3px]'>Individual</p>
                </div>
              </button>}

            {/* Business account button active and inactive state */}
            {isBusinessAccount ? <div className='flex items-start w-full'>
              <img src='check.svg' alt='check' className='absolute mt-[-10px] ml-[-10px]' />
              <button onClick={onChangeAccountType} className='bg-primary-300 border-2 border-primary-200 w-full py-4 px-[18px] rounded-[15px] justify-center items-center'>
                <div className='flex pl-3 items-center gap-4 relative'>
                  <svg className='w-[18px] lg:w-8 h-[18px] lg:h-8' viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="vuesax/bold/building-4">
                      <g id="building-4">
                        <path id="Vector" d="M15.5934 4H7.28672C4.63338 4 3.30005 5.34667 3.30005 8.02667V29.3333H10.5V24.3333C10.5 23.7867 10.9534 23.3333 11.5 23.3333C12.0467 23.3333 12.5 23.7733 12.5 24.3333V29.3333H19.5667V8.02667C19.5667 5.34667 18.2467 4 15.5934 4ZM14.8334 17H8.23338C7.68672 17 7.23338 16.5467 7.23338 16C7.23338 15.4533 7.68672 15 8.23338 15H14.8334C15.38 15 15.8334 15.4533 15.8334 16C15.8334 16.5467 15.38 17 14.8334 17ZM14.8334 12H8.23338C7.68672 12 7.23338 11.5467 7.23338 11C7.23338 10.4533 7.68672 10 8.23338 10H14.8334C15.38 10 15.8334 10.4533 15.8334 11C15.8334 11.5467 15.38 12 14.8334 12Z" fill="#FFF" />
                        <path id="Vector_2" d="M31.1666 28.3332H28.1399V24.3332C29.4066 23.9198 30.3266 22.7332 30.3266 21.3332V18.6665C30.3266 16.9198 28.8999 15.4932 27.1533 15.4932C25.4066 15.4932 23.9799 16.9198 23.9799 18.6665V21.3332C23.9799 22.7198 24.8866 23.8932 26.1266 24.3198V28.3332H1.83325C1.28659 28.3332 0.833252 28.7865 0.833252 29.3332C0.833252 29.8798 1.28659 30.3332 1.83325 30.3332H27.0733C27.0999 30.3332 27.1133 30.3465 27.1399 30.3465C27.1666 30.3465 27.1799 30.3332 27.2066 30.3332H31.1666C31.7133 30.3332 32.1666 29.8798 32.1666 29.3332C32.1666 28.7865 31.7133 28.3332 31.1666 28.3332Z" fill="#FFF" />
                      </g>
                    </g>
                  </svg>
                  <div className='flex h-full w-full items-center pr-6'>
                    <p className='text-primary-300 text-white font-medium mb-[-3px] text-base lg:text-[22px]'>Business</p>
                  </div>
                  <img src='eclispe.svg' alt='check' className='absolute left-full opacity-15 ml-[-20px]' />
                </div>
              </button>
            </div> :
              <button onClick={onChangeAccountType} className='bg-[#EAECF0] w-full rounded-[15px] py-4 px-[18px] justify-center items-center'>
                <div className='flex w-full items-center text-white gap-4'>
                  <svg className='w-[18px] lg:w-8 h-[18px] lg:h-8' viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="vuesax/bold/building-4">
                      <g id="building-4">
                        <path id="Vector" d="M15.5934 4H7.28672C4.63338 4 3.30005 5.34667 3.30005 8.02667V29.3333H10.5V24.3333C10.5 23.7867 10.9534 23.3333 11.5 23.3333C12.0467 23.3333 12.5 23.7733 12.5 24.3333V29.3333H19.5667V8.02667C19.5667 5.34667 18.2467 4 15.5934 4ZM14.8334 17H8.23338C7.68672 17 7.23338 16.5467 7.23338 16C7.23338 15.4533 7.68672 15 8.23338 15H14.8334C15.38 15 15.8334 15.4533 15.8334 16C15.8334 16.5467 15.38 17 14.8334 17ZM14.8334 12H8.23338C7.68672 12 7.23338 11.5467 7.23338 11C7.23338 10.4533 7.68672 10 8.23338 10H14.8334C15.38 10 15.8334 10.4533 15.8334 11C15.8334 11.5467 15.38 12 14.8334 12Z" fill="#1F1844" />
                        <path id="Vector_2" d="M31.1666 28.3332H28.1399V24.3332C29.4066 23.9198 30.3266 22.7332 30.3266 21.3332V18.6665C30.3266 16.9198 28.8999 15.4932 27.1533 15.4932C25.4066 15.4932 23.9799 16.9198 23.9799 18.6665V21.3332C23.9799 22.7198 24.8866 23.8932 26.1266 24.3198V28.3332H1.83325C1.28659 28.3332 0.833252 28.7865 0.833252 29.3332C0.833252 29.8798 1.28659 30.3332 1.83325 30.3332H27.0733C27.0999 30.3332 27.1133 30.3465 27.1399 30.3465C27.1666 30.3465 27.1799 30.3332 27.2066 30.3332H31.1666C31.7133 30.3332 32.1666 29.8798 32.1666 29.3332C32.1666 28.7865 31.7133 28.3332 31.1666 28.3332Z" fill="#1F1844" />
                      </g>
                    </g>
                  </svg>

                  <p className='text-primary-300 font-medium text-base lg:text-[22px] mb-[-3px]'>Business</p>
                </div>
              </button>}
          </div>


          <div className="flex flex-col gap-4 w-full">
            {/* Business Account Form */}
            {isBusinessAccount ? <FormInput label='Business name' type='text' value={businessName} setValue={setBusinessName} /> : null}
            {/* Individual Account Form */}
            <FormInput label='First name' type='text' value={firstName} setValue={setFirstName} />
            <FormInput label='Surname' type='text' value={lastName} setValue={setLastName} />
            <FormInput label='Phone number' type='text' value={phoneNumber} setValue={setPhoneNumber} />
            <FormInput label='Email' type='text' value={email} setValue={setEmail} />
            <FormInput label='Password' type='password' value={password} setValue={setPassword} />



            <div className='w-full mt-4'>
              <Button onButtonClick={handleSignUp}>
                {loadingProgress ? <div className='w-full justify-center h-full items-center'>
                  <ClipLoader color='#fff' size={20} />
                </div> : 'Continue'}
              </Button>
            </div>
          </div>

          {/* <div className='flex w-full justify-center gap-6 items-center my-[43px]'>
            <img src='line.svg' alt='line' />
            <p className='text-primary-100 text-2xl'>or</p>
            <img src='line.svg' alt='line' />
          </div>

          <div className="flex flex-col gap-4">
            <Button isSecondary={true}>
              <div className='flex items-center gap-3 w-full justify-center'>
                <img src='google.svg' alt='google' />
                <p className='text-primary-400 group-hover:text-white text-base font-semibold'>Sign in with Google</p>
              </div>
            </Button>
            <Button isSecondary={true}>
              <div className='flex items-center gap-3 w-full justify-center'>
                <img src='facebook.svg' alt='google' />
                <p className='text-primary-400 group-hover:text-white text-base font-semibold'>Sign in with Facebook</p>
              </div>
            </Button>
          </div>

          <div className='w-full justify-center mt-8 text-center items-center'>
            <Link to=''>
              <p className='text-primary-100 text-lg'>Forgot password?</p>
            </Link>
          </div> */}
          <div className='w-full hidden mt-[43px] text-center justify-center items-center md:flex'>
            <p className='text-primary-100 text-lg'>Already have an account? <Link to='/login'><span className='text-primary-200'>Sign in</span></Link></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
