import './App.css';

import { BrowserRouter as Router, Switch, Route, Redirect  } from 'react-router-dom';
import ActiveOrders from './components/pages/ActiveOrders/ActiveOrders';
import Profile from './components/pages/Profile/Profile';
import Request from './components/pages/Request/Request';
import RequestCp from './components/pages/RequestCp/RequestCp';
import OtpVerify from './components/pages/OtpVerification/OtpVerification';
import Login from './components/pages/Login/Login';
import Support from './components/pages/Support/Support';
import OrderHistory from './components/pages/OrderHistory/OrderHistory';
import FeedbackPage from './components/pages/Feedback/FeedbackPage';
// import TestComponent from './components/pages/testComp/TestComponent';
import ProtectedRoutes from './components/ProtectedRoutes';
import SignUp from './components/pages/SignUp/SignUp';
import FindUs from './components/pages/FindUs/FindUs';
import Notifications from './components/pages/Notifications/Notifications';
import OrderDetailsPage from './components/pages/OrderDetailsPage/OrderDetailsPage';
import SplashScreen from './components/pages/SplashScreen/SplashScreen';
import Onboarding from './components/pages/Onboarding/Onboarding';

function App() {
  let loggedIn = false;
  let isFirstTime = true;
  let user = localStorage.getItem('user');
  let firsttime = localStorage.getItem('isFirstTime');

  const userObj = JSON.parse(user);
  if (user) {
    loggedIn = true
  }
  if (firsttime) {
    isFirstTime = Boolean(firsttime)
  }


  var React = require('react');

  return (
    <div>
      <Router>
        <Switch>

          <Route path="/onboarding" exact>
            <Onboarding />
          </Route>

          <Route exact path="/" render={() => (
              <Redirect to="/dashboard" />
          )} />

          <Route path="/signup" exact>
            <SignUp />
          </Route>

          <Route path="/login" exact>
            <Login />
          </Route>

          <Route path="/otpverification" exact>
            <OtpVerify />
          </Route>
          {/* <Route path="/"  exact>
          <SplashScreen isAuth={loggedIn} user={user} isFirstTime={isFirstTime}/>
        </Route> */}

          {/* <ProtectedRoutes path="/" component={ActiveOrders} isAuth={loggedIn} user={userObj} exact/> */}
          <ProtectedRoutes path="/dashboard" component={ActiveOrders} isAuth={loggedIn} user={userObj} isFirstTime={isFirstTime} />
          <ProtectedRoutes path="/order/details/:orderId" component={OrderDetailsPage} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/profile" component={Profile} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/support" component={Support} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/feedback" component={FeedbackPage} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/request" component={Request} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/requestcp" component={RequestCp} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/orderhistory" component={OrderHistory} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/find-us" component={FindUs} isAuth={loggedIn} user={userObj} />
          <ProtectedRoutes path="/notifications" component={Notifications} isAuth={loggedIn} user={userObj} />

        </Switch>
      </Router>
    </div>
  );
}

export default App;
