import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
        primary: {
          main: "#1C1045",
        },
        secondary: {
          main: "#E56F09",
        },
      },

    color: {
        appbar : {
            backgroundColor : "#fff"
        }
    },
    myButton: {
        backgroundColor: "red",
        color: "white",
        border: "1px solid black",
      },
});