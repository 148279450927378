import React, { useEffect, useState } from 'react'
import { makeStyles, Card, Typography, CardContent, CardActions } from '@material-ui/core';
import RightbarNav from '../../RightBarNav';
import Leftbar from '../../Leftbar';
import "./support.css";
import { Modal } from 'react-responsive-modal';
import axiosInstance from '../../../helpers/axios';
import { BeatLoader } from 'react-spinners';
import { formatDate } from '../../../helpers/date';



const useStyles = makeStyles((theme)=> ({
    userListDelete: {
        color: "red",
        cursor: "pointer",
        fontSize: 30
    },
    
    userListEdit: {
        color: "#085279",
        border: "none",
        fontSize: 40,
        backgroundColor: "#fff",
        cursor: "pointer",
        marginRight: 20
    },

    button:{
        backgroundColor: "#E56F09",
        borderRadius: "20px",
        width: "170px",
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 18,
        fontWeight: "550",
        color: "#1C1045",
        position: "fixed",
        bottom: 50,
        right: 10
    },
    box: {
        backgroundColor: "#E56F09",
        width: "100%",
        height: "30%",

    }
})); 

const Support = ({user, toggleLeftBar, setToggleLeftBar}) => {

    const [open, setOpen] = useState(false);
    const [supportArticlesArr, setSupportArticlesArr] = useState([]);
    const [articleBody, setArticleBody] = useState("");
    const [articleTitle, setArticleTitle] = useState("");
    const [loadingProgress, setLoadingProgress] = useState(true);

    useEffect(() => {
        const fetchUserOrders = async()=>{
            try {
                const res = await axiosInstance.get('/Support');
                if (res.data.success === true) {
                    // setSupportArticlesArr(res.data.data)
                    setSupportArticlesArr(res.data.data.sort((dateA, dateB) => new Date(dateB.publishDate) - new Date(dateA.publishDate)))

                    setTimeout(() => {
                        setLoadingProgress(false);
                    }, 3000);
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchUserOrders();       
    }, []);
    
    const handleOpenModal = (article) => {
        setArticleTitle(article.caption);
        setArticleBody(article.body);
        setOpen(true);
    }
    
    const closeUserModal = () => {
        setOpen(false);
    }    


    useEffect(() => {
        setToggleLeftBar(false);
    }, [])

    return (
        <div className="supportPage">
            <div className="menusidebar">
                <Leftbar />
            </div> 
            <div className="mobilemenusidebar" style={ toggleLeftBar ? {width: "200px"} : undefined}>
                <Leftbar toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
            </div>
            <div className="supportFeed">
            <RightbarNav pageTitle = "Knowledge Base" toggleLeftBar={toggleLeftBar} handleToggleLeftBar={setToggleLeftBar}/>
                <h4 style={{fontSize: "25px", marginBottom: "10px", marginTop: "20px", color: "#1C1045"}}> What can we do for you </h4>
                <div className="cardDivs" style={{display: "flex"}}> 
                {
                    loadingProgress ? (
                        <div className="notificationsLoadingProgressDiv">
                        <BeatLoader color={"#1C1045"} loading={loadingProgress} size={15} margin={2} /> 
                        </div>
                    ) :
                    supportArticlesArr.map((article) => {
                        return (
                            <div className="cards">
                                <Card className="cardStyle">
                                    <CardContent>
                                        <Typography variant="h5"  gutterBottom style={{color: "#1C1045"}}>
                                            {article.caption}
                                        </Typography>
                                        <Typography variant="body2">
                                            {formatDate(article.publishDate)}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <button className="viewMoreButton" onClick={() => handleOpenModal(article)}>Learn More</button>
                                    </CardActions>
                                </Card>
                            </div>
                        )
                    })
                }
                </div>
                <Modal 
                    open={open}
                    onClose={closeUserModal}
                    center
                >
                        <h5 className="modalHeader">{articleTitle}</h5>
                        <div style={{wordWrap: "break-word"}}>
                            <p className="modalBody" dangerouslySetInnerHTML={{__html: articleBody}}></p>
                        </div>
                </Modal>
                {/* <ButtonBase className={ classes.button }>Back</ButtonBase> */}
            </div>
        </div>
    )
}

export default Support
