export default function Button({ children, onButtonClick, isSecondary, disabled }) {
    if (isSecondary) {
        return (
            <button className="hover:bg-primary-300 hover:text-white group bg-transparent border hover:border-primary-300 border-[#D0D5DD] text-primary-300 text-base font-semibold p-3 w-full rounded-[30px]" onClick={onButtonClick}>{children}</button>
        )
    } else {
        return (
            <button disabled={disabled} className="hover:bg-primary-200 hover:text-lg transition-all disabled:bg-[#B4B2C1] duration-300 ease-in bg-primary-300 text-white text-base font-semibold p-3 w-full rounded-[30px]" onClick={onButtonClick}>{children}</button>
        )
    }
}