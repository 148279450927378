import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { css } from "@emotion/react";
import { BeatLoader, ClipLoader } from "react-spinners";
import { useHistory } from 'react-router';
import logoLarge from "../../../assets/img/tb-logo-xd.png";
import './styles.css';
import axiosInstance from '../../../helpers/axios';
import Button from '../../Button';
import { message } from 'antd';

const useStyles = makeStyles((theme) => ({

    logoGrp: {
        marginBottom: "30px",
        paddingTop: "30px",

    },

    logoLg: {
        display: "block",
        maxWidth: "85%",
        height: "auto",
    },

    item: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "50px",
        margin: "auto",
        width: "100%"
    },

    label: {
        color: "#D3D3D3",
    },

    button: {
        backgroundColor: "#E56F09",
        borderRadius: "20px",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 18,
        fontWeight: "700",
        color: "#1C1045",
        border: "none",
        cursor: "pointer",
        textAlign: "center",
    },
}));


const OtpVerify = () => {

    const classes = useStyles();

    const [otpCode, setOtpCode] = useState("");
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [canRequestOTPResend, setCanRequestOTPResend] = useState(false);
    const email = localStorage.getItem('email');
    const history = useHistory();
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);
    const [digitOne, setDigitOne] = useState("");
    const [digitTwo, setDigitTwo] = useState("");
    const [digitThree, setDigitThree] = useState("");
    const [digitFour, setDigitFour] = useState("");
    const [digitFive, setDigitFive] = useState("");
    const [digitSix, setDigitSix] = useState("");

    function goBack() {
        history.goBack();
    }

    function isNumber(str) {
        if (str.trim() === '') {
            return false;
        }

        return !isNaN(str);
    }

    const handleDigitOne = (e) => {
        const value = e.target.value
        if (value === '') {
            setDigitOne(value)
            return
        }
        if (!isNumber(value)) return;
        setDigitOne(value)
        setFocus(2)
    }


    const handleDigitTwo = (e) => {
        const value = e.target.value
        if (value === '') {
            setDigitTwo(value)
            setFocus(1)
            return
        }
        if (!isNumber(value)) return;
        setDigitTwo(value)
        setFocus(3)

    }

    const handleDigitThree = (e) => {
        const value = e.target.value
        if (value === '') {
            setDigitThree(value)
            setFocus(2)
            return
        }
        if (!isNumber(value)) return;
        setDigitThree(value)
        setFocus(4)
    }

    const handleDigitFour = (e) => {
        const value = e.target.value
        if (value === '') {
            setDigitFour(value)
            setFocus(3)
            return
        }
        if (!isNumber(value)) return;
        setDigitFour(value)
        setFocus(5)
    }

    const handleDigitFive = (e) => {
        const value = e.target.value
        if (value === '') {
            setDigitFive(value)
            setFocus(4)
            return
        }
        if (!isNumber(value) && value === '') return;
        setDigitFive(value)
        setFocus(6)
    }

    const handleDigitSix = (e) => {
        const value = e.target.value
        if (value === '') {
            setDigitSix(value)
            setFocus(5)
            return
        }
        if (!isNumber(value)) return;
        setDigitSix(value)
    }


    const handleVerifyOtp = async () => {
        setLoadingProgress(true)
        try {
            const res = await axiosInstance.post(`/Auth/VerifyToken`, {
                email: email,
                token: `${digitOne}${digitTwo}${digitThree}${digitFour}${digitFive}${digitSix}`
            });

            if (res.status === 200) {
                localStorage.setItem('user', JSON.stringify(res.data.data.user));
                localStorage.setItem('isFirstTime', 'false');
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 2000);
            } else {
                message.error(res.data.info)
            }
            setLoadingProgress(false);

        } catch (error) {
            setLoadingProgress(false)
            setResponseMessage(<Typography className="errorMessage">{error.response.data.info}</Typography>)
            setOtpCode("");
        }
    }

    const handleResendOTP = async (e) => {
        e.preventDefault();
        setCanRequestOTPResend(false);
        try {
            const res = await axiosInstance.post(`/Auth/SendToken`, {
                email: email,
            }, { headers: { 'Content-Type': 'Application/json' } });

            if (res.status === 200) {
                setTimeout(() => {
                    setCanRequestOTPResend(true);
                }, 15000);
                message.success('OTP code sent!')
            }
        } catch (error) {
            console.log(error)
            setLoadingProgress(false)
            setResponseMessage(<Typography className="errorMessage">{error.response.data.info}</Typography>)
            setOtpCode("");
        }
    }

    const handleValidateOtp = (e) => {
        const val = (e.target.validity.valid) ? e.target.value : otpCode;
        setOtpCode(val)
    }

    useEffect(() => {
        setTimeout(() => {
            setCanRequestOTPResend(true)
        }, 10000);
    }, []);

    const setFocus = (inputNumber) => {
        switch (inputNumber) {
            case 1:
                inputRef1.current && inputRef1.current.focus()
                break;
            case 2:
                inputRef2.current && inputRef2.current.focus()
                break;
            case 3:
                inputRef3.current && inputRef3.current.focus()
                break;
            case 4:
                inputRef4.current && inputRef4.current.focus()
                break;
            case 5:
                inputRef5.current && inputRef5.current.focus()
                break;
            case 6:
                inputRef6.current && inputRef6.current.focus()
                break;

        }
    }

    return (
        <div className='md:bg-[#F5F5F5] h-screen font-inter'>
            <div className='flex w-full h-full justify-center md:items-center md:p-4 lg:p-8'>
                <div className='bg-white rounded-lg p-8'>
                    <div className='flex flex-col gap-2 w-full justify-center items-center'>
                        <img src='shield.svg' alt='shield' className='w-[60px] h-[70px]' />
                        <p className='text-2xl md:text-3xl text-primary-300 font-semibold'>OTP Verification</p>
                        <p className='text-primary-100 text-base md:text-lg font-normal mt-2 text-center'>Please enter the 6 digit code that was sent to <span className='font-semibold text-primary-300'>{email}</span></p>
                        {canRequestOTPResend && <p onClick={handleResendOTP} className='text-primary-100 text-base md:text-lg font-normal mt-2 text-center'>Didn’t get a code <span className='font-semibold text-primary-300 cursor-pointer'>resend</span></p>}
                        <p className='text-sm font-medium mt-6'>Secure code</p>
                    </div>

                    <div className='flex justify-center mt-1 gap-3 items-start w-full'>
                        <input type='text' ref={inputRef1} placeholder='0' className='text-center rounded-lg max-w-[48px] md:max-w-[63px] text-[38px] lg:text-5xl text-primary-300 focus:ring-primary-200 outline-none ring-2 ring-[#D0D5DD] font-medium py[10px] px-2 md:px-4' onChange={handleDigitOne} value={digitOne} maxLength={1} />
                        <input type='text' ref={inputRef2} placeholder='0' className='text-center rounded-lg max-w-[48px] md:max-w-[63px] text-[38px] lg:text-5xl text-primary-300 focus:ring-primary-200 outline-none ring-2 ring-[#D0D5DD] font-medium py[10px] px-2 md:px-4' onChange={handleDigitTwo} value={digitTwo} maxLength={1} />
                        <input type='text' ref={inputRef3} placeholder='0' className='text-center rounded-lg max-w-[48px] md:max-w-[63px] text-[38px] lg:text-5xl text-primary-300 focus:ring-primary-200 outline-none ring-2 ring-[#D0D5DD] font-medium py[10px] px-2 md:px-4' onChange={handleDigitThree} value={digitThree} maxLength={1} />
                        <input type='text' ref={inputRef4} placeholder='0' className='text-center rounded-lg max-w-[48px] md:max-w-[63px] text-[38px] lg:text-5xl text-primary-300 focus:ring-primary-200 outline-none ring-2 ring-[#D0D5DD] font-medium py[10px] px-2 md:px-4' onChange={handleDigitFour} value={digitFour} maxLength={1} />
                        <input type='text' ref={inputRef5} placeholder='0' className='text-center rounded-lg max-w-[48px] md:max-w-[63px] text-[38px] lg:text-5xl text-primary-300 focus:ring-primary-200 outline-none ring-2 ring-[#D0D5DD] font-medium py[10px] px-2 md:px-4' onChange={handleDigitFive} value={digitFive} maxLength={1} />
                        <input type='text' ref={inputRef6} placeholder='0' className='text-center rounded-lg max-w-[48px] md:max-w-[63px] text-[38px] lg:text-5xl text-primary-300 focus:ring-primary-200 outline-none ring-2 ring-[#D0D5DD] font-medium py[10px] px-2 md:px-4' onChange={handleDigitSix} value={digitSix} maxLength={1} />

                    </div>

                    <div className='flex flex-col md:flex-row gap-2 mt-8'>
                        <Button isSecondary={true} onButtonClick={goBack}>
                            Go back
                        </Button>
                        <Button disabled={!digitOne || !digitTwo || !digitThree || !digitFour || !digitFive || !digitSix} onButtonClick={handleVerifyOtp}>
                            {loadingProgress ? <div className='w-full justify-center h-full items-center'>
                                <ClipLoader color='#fff' size={20} />
                            </div> : 'Verify'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpVerify
