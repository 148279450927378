import Leftbar from "./Leftbar";
import TopNav from "./TopNav";

export default function Layout({pageTitle, children, showMobileNav, user}) {
    return (
        <div className="flex h-full justify-start font-inter">
            <Leftbar show={showMobileNav} />

            <div className="w-full h-full flex-1 flex-col">
                {/* TopBar */}
                <TopNav pageTitle={pageTitle} user={user}/>

                {/* Main Content */}
                <main className='bg-gray-200 w-full h-full md:py-6 h-full mt-[32px] md:mt-[72px] lg:pl-[352px] lg:pr-[52px] transition-all duration-400'>
                    <div className="bg-white h-full p-6 md:p-8 rounded-[10px]">
                        {children}
                    </div>
                </main>
            </div>
        </div>
    )
}