import { ArrowRight } from "iconsax-react";
import Button from "./Button";
import FormInput from "./FormInput";

export default function DeviceType({goBack, goToNext, deviceType, setDeviceType}) {
    return (
        <div className="flex flex-col lg:w-[848px]">
            <p className="text-primary-300 text-[40px] font-semibold mb-8">Which <span className="text-[#98A2B3]">device</span> are you using?</p>
            <div className="flex flex-col mb-10">
                <FormInput label={'Device type'} value={deviceType} setValue={setDeviceType} type={'text'} placeholder={'example: iPhone 13 promax'} />
            </div>

            <div className="flex w-full">
                <div className="flex gap-3">
                    <Button isSecondary={true} onButtonClick={goBack}>
                        Go back
                    </Button>
                    <Button onButtonClick={goToNext} disabled={!deviceType}>
                        <div className="flex text-white gap-2 items-center">
                            <p className="text-base font-semibold">Continue</p>
                            <ArrowRight size="20" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}